import React, { Component, createRef, Fragment } from "react";
import PropTypes from "prop-types";

import MenuOverlay from "./overlay/MenuOverlay";

export default class OverlayManager extends Component
{
    static propTypes = {
        owner: PropTypes.any,
        location: PropTypes.object,
        showMenu: PropTypes.bool,
        onMenuShow: PropTypes.func,
        onClose: PropTypes.func
    }

    constructor(props)
    {
        super(props);

        this.menuRef = createRef();

        this.state = { 
            isMenuOpen: false, 
            resized: false,
            overlays: [] 
        }
    }

    addOverlay(overlayProp, id)
    {
        const { overlays } = this.state;

        // find if overlay item exists by id
        let itemExists = false;
        for (let i = 0; i < overlays.length; i++)
        {
            if (overlays[i].id === id)
            {
                itemExists = true;
                break;
            }
        }

        if (itemExists) return;

        // add overlay
        overlays.push({ id: id, overlay: overlayProp });

        this.setState( { overlays } );
    }

    removeOverlay(id)
    {
        const { overlays } = this.state;

        // find overlay item by id and remove
        for (let i = overlays.length - 1; i >= 0; i--)
        {
            if (overlays[i].id === id)
            {
                overlays.splice(i, 1);
                break;
            }
        }

        this.setState( { overlays } );
    }

    removeAll()
    {
        const { overlays } = this.state;

        // find overlay item by id and remove
        for (let i = overlays.length - 1; i >= 0; i--) overlays.splice(i, 1);

        this.setState( { overlays } );
    }

    hideMenu()
    {
        // calling transitionOut will trigger onMenuTransitionComplete()
        this.menuRef.current.transitionOut();
    }

    onMenuShow(isMenuOpen, withKey)
    {
        const { onMenuShow } = this.props;

        onMenuShow && onMenuShow(isMenuOpen, withKey);
    }

    onResize()
    {
        const { resized } = this.props;
        this.setState({ resized: !resized });
    }

    render()
    {
        const { overlays } = this.state;
        const { showMenu, owner, location } = this.props;

        let isAriaHidden = (overlays.length === 0) ;
        if (showMenu) isAriaHidden = false;

        let styles = {}
        const res = owner.device.resolution();
        styles.height = res.height + "px";

        return <Fragment>

            <div 
                id="__overlay" 
                className="overlay-manager" 
                role="dialog" 
                aria-hidden={isAriaHidden} 
                style={styles}
            >
                {overlays.map((item, index) => {
                    return (<Fragment key={index}>{item.overlay}</Fragment>);
                })}

                { showMenu && <MenuOverlay 
                    ref={this.menuRef} 
                    location={location} 
                    pageMan={owner}
                    onHideMenu={() => this.hideMenu()}
                    onTransitionComplete={(isMenuOpen, withKey) => this.onMenuShow(isMenuOpen, withKey)} 
                /> }
            </div>
            
        </Fragment>
    }
}