/**
 * Author: Rafael Emmanuelli / Insytive 09-03-2020
 * 
 * Header
 * 
*/
import * as React from "react"
import { Component, Fragment, createRef } from "react";
import { navigate } from "gatsby";
import PropTypes from "prop-types";
import classNames from "classnames";
import gsap from "gsap";

import LogoIcon from "../../assets/casa_lupita_horiz_logo.svg";
import SoundButton from "../components/media/SoundButton";
import MenuButton from "./MenuButton";
import Link from "./gatsby/Link";

export default class Header extends Component
{
    static propTypes = {
        device: PropTypes.any,
        onMenuClick: PropTypes.func,
        location: PropTypes.object,
    }

    get audio() { return this.sndBtnR.current; }

    constructor(props)
    {
        super(props);

        this.headerRef = createRef();
        this.sndBtnR = createRef();
        this.menuBtnR = createRef();
        this.tweener = null;

        this.onMenuClick = this.onMenuClick.bind(this);

        this.state = {
            isShowing: false,
            isLocked: false,
            isFrozen: false
        }
    }

    enable(lock = false, deepFreeze = false)
    {
        this.setState({ isLocked: lock, isFrozen: deepFreeze });
    }

    show() 
    {
        const { isShowing } = this.state;

        if (isShowing) return;
        
        this.tweener = gsap.fromTo(this.headerRef.current, { autoAlpha: 0 },  { autoAlpha: 1, duration: 0.75, ease: "power1.out", onComplete: () => {
            this.setState({ isShowing: true });
        } })
    }

    hide() 
    {
        this.tweener = gsap.to(this.headerRef.current, { autoAlpha: 0, duration: 0.75, ease: "power1.out", onComplete: () => {
            this.setState({ isShowing: false });
        } })
    }

    onMenuClick(isOpen, isKey)
    {
        const { onMenuClick } = this.props;

        onMenuClick && onMenuClick(isOpen, isKey);
    }

    toggleMenuIcon(isOpen)
    {
        this.menuBtnR.current.toggleMenu(isOpen);
    }

    shouldComponentUpdate(nextProps, nextState)
    {
        const { isShowing, isLocked, isFrozen } = this.state;

        if (isShowing !== nextState.isShowing
            || isLocked !== nextState.isLocked 
            || isFrozen !== nextState.isFrozen) return true;

        return false;
    }

    componentWillUnmount()
    {
        this.tweener && this.tweener.kill();
        this.tweener = null;
    }

    render()
    {
        const { device, location } = this.props;
        const { isShowing, isLocked, isFrozen } = this.state;

        const href = `${location.origin}/`;

        return (
            <Fragment>
                <header ref={this.headerRef} id={"__header"} className={classNames({
                        "is-showing": isShowing, 
                        "is-frozen": isFrozen, 
                        "is-locked": isLocked})}
                    >
                    <div className="hli">
                        <div className={"logo"}>
                            
                            <Link 
                                to={href} 
                                className="logo-link"
                                activeClassName="active"
                                partiallyActive={false}
                                aria-label="Home Button"
                                tabIndex={0}
                                onClick={(e) => {
                                    // If we are on the home page, reload it.
                                    if (e.target.classList.contains("active")) 
                                    {
                                        e.preventDefault();
                                        navigate("/");
                                        return;
                                    }
                                }}
                            >
                                <LogoIcon/>
                            </Link>
                        </div>
                    </div>

                    <div className="hri">
                        <SoundButton ref={this.sndBtnR} device={device} />
                        <div className="menu">
                            <MenuButton ref={this.menuBtnR} location={location} onClick={this.onMenuClick} />
                        </div> 
                    </div>
                                       
                </header>
            </Fragment>
        )
    }
}