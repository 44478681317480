/**
 * Author: Rafael Emmanuelli / Insytive 09-03-2020
 * 
 * RichLayoutBody
 * 
*/

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export default class RichLayoutBody extends Component
{
    static propTypes = {
        classes: PropTypes.string,
        body: PropTypes.object,
    }
    
    static defaultProps = {
        classes: "",
        body: null
    }

    render()
    {
        const { classes, body, children } = this.props;

        const classNamesList = classNames("richlayout-body", classes);

        return (
            <Fragment>
                <div className={classNamesList}>
                    {body && <div className="bodytext">
                        <div className="text">{body}</div>
                    </div>}
                    {children}
                </div>
            </Fragment>
        )
    }
}