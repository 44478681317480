/**
 * Author: Rafael Emmanuelli
 * 
 * Video Time Control
 * 
*/

import React, { Fragment, Component, createRef } from "react";
import PropTypes from "prop-types";
import { curTimeText, secondsToTime } from "../../../system/MiscTools";

export default class VideoTime extends Component
{
    static propTypes = {
        videoEl: PropTypes.any,
        currentTime: PropTypes.number,
        durationTime: PropTypes.number
    }

    static defaultProps = {
        currentTime: 0,
        durationTime: 0
    }
    
    constructor(props)
    {
        super(props);

        this.ref = createRef();

        this.state = {
            isTime: false
        }
    }

    onClick()
    {
        const { isTime } = this.state;

        this.setState({ isTime: !isTime });
    }

    render()
    {
        const { isTime } = this.state;

        const { currentTime: cT, durationTime: dT } = this.props;       

        const rTval = dT - cT;              // remaining time
        const eT = curTimeText(cT);         // aria elapsed time label
        const rT = curTimeText(rTval);      // aria remaining time label

        return (
            <Fragment>
                <button ref={this.ref} 
                    className="video-time-box" 
                    onClick={(e) => this.onClick(e)} 
                >
                    {!isTime && <div className="video-current-time" aria-label={`${eT} elapsed`}>{secondsToTime(cT).display}</div>}
                    {isTime && <div className="video-remain-time" aria-label={`${rT} remaining`}>{secondsToTime(rTval).display}</div>}
                </button>
            </Fragment>
        )
    }
}