/**
 * Author: Rafael Emmanuelli / 11-26-2020
 * 
 * Focus Manager
 * 
*/

export const KEY_CODE = {
    TAB: 9,
    ESCAPE: "Escape"
};

export const FOCUSABLE = 'a[href], input:not([disabled]), button:not([disabled]), [tabindex="0"]';

export default class FocusManager
{
    constructor(props)
    {
        const { el, focusable, isGuided, ignoreEsc, lastIdx, noHead } = props;

        this.el = el;
        this.focusable = focusable ? focusable : [];
        this.isGuided = isGuided;
        this.ignoreEsc = ignoreEsc;
        this.noHead = noHead;
        this.firstEl = null;
        this.lastEl = null;
        this.hasInit = false;
        this.curIdx = lastIdx ? lastIdx : 0;
        this.lastIdx = this.curIdx;

        // events
        this.onKey = this.onKey.bind(this);
        this.onTab = new Event('focus_tab');
        this.closeEvent = new Event('focus_close');
    }

    init()
    {
        // set first and last focusable elements
        this.firstEl = this.focusable[0];
        this.lastEl = this.focusable[this.focusable.length - 1];

        // init key handler event
        window.addEventListener('keydown', this.onKey);
    }

    setBasePage()
    {
        // get current element focusable items
        this.focusable = [].slice.call(this.el.querySelectorAll(FOCUSABLE));

        // get header elements
        if (!this.noHead)
        {
            const header = document.getElementById("__header");
            this.focusable.push(header.querySelector(".logo-link"));
            this.focusable.push(header.querySelector(".sound-button"));
            this.focusable.push(header.querySelector(".menu-icon-button"));
        }

        this.init();
    }

    onKey(e)
    {
        // console.log("keyboardHandler", document.activeElement);

        if (e.keyCode === KEY_CODE.TAB) 
        {
            // at first TAB attempt, take them to first el
            if (!this.hasInit)
            {
                e.preventDefault();
                this.hasInit = true;
                this.focusable[this.lastIdx].focus();
                // this.firstEl.focus();

                window.dispatchEvent(this.onTab);
                return;
            }

            // control tab focus
            if (this.isGuided)
            {
                e.preventDefault();

                if (e.shiftKey)
                {
                    if (this.curIdx > 0) this.curIdx--;
                    else this.curIdx = this.focusable.length - 1;
                }
                else 
                {
                    if (this.curIdx < this.focusable.length - 1) this.curIdx++;
                    else this.curIdx = 0;
                }

                this.lastIdx = this.curIdx;
                this.focusable[this.curIdx].focus();
                return;
            }

            // Loop Focus
            if (e.shiftKey && document.activeElement === this.firstEl) 
            {
                //console.log("FIRST element is equal to activeElement", document.activeElement);

                e.preventDefault();
                this.lastEl.focus();
            } 
            else if (!e.shiftKey && document.activeElement === this.lastEl) 
            {
                //console.log("LAST element is equal to activeElement", document.activeElement);

                e.preventDefault();
                this.firstEl.focus();
            }
        }
        else if (e.key === KEY_CODE.ESCAPE && !this.ignoreEsc)
        {
            this.dispose();
            window.dispatchEvent(this.closeEvent);            
        }
    }

    dispose()
    {
        // console.log("focus.dispose");

        this.lastIdx = this.curIdx;

        this.focusable = null;
        this.firstEl = null;
        this.lastEl = null;
        this.el = null;
        window.removeEventListener('keydown', this.onKey);
    }

    
}