import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import FocusManager from "../../system/FocusManager";

export default class BaseOverlay extends Component
{
    static propTypes = {
        baseClassName: PropTypes.string,
        device: PropTypes.object,
        onClose: PropTypes.func
    }

    static defaultProps = {
        baseClassName: "overlay"
    }

    constructor(props)
    {
        super(props);

        this._focus = null;
        this._tween = null;

        this.onResize = this.onResize.bind(this);
        window.addEventListener("resize", this.onResize);
    }

    setFocus(props)
    {
        // console.log("setFocus")

        this.closeFocus = this.closeFocus.bind(this);
        this._focus = new FocusManager(props);
        this._focus.setBasePage();
        window.addEventListener("focus_close", this.closeFocus);
    }

    closeFocus()
    {
        
    }

    onResize()
    {

    }

    componentWillUnmount()
    {
        window.removeEventListener("resize", this.onResize);

        this.dispose();
    }

    dispose()
    {
        if (this._tween) this._tween.kill();
        if (this._focus) {
            window.removeEventListener("focus_close", this.closeFocus);
            this._focus.dispose();
        }
    }

    render()
    {
        return <Fragment></Fragment>
    }
}