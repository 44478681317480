/**
 * Utils
 * 
 * AssetManager
 * 
 */
 import { withPrefix } from 'gatsby';

 export default class AssetManager
 {
     constructor(props)
     {
         const { log, device } = props;
 
         this.log = log;
         this.isLazy = true;
         this.device = device;
     }
 
     initialize(props)
     {
         const { name: libName, queue, path, isLazy, onProgress } = props;
 
         if (!window.__app_assets) window.__app_assets = {};
 
         this.isLazy = isLazy;       // lazy loading
         this.name = libName;        // asset list name
         this.queued = queue;        // list of queued assets 
         this.count = 0;             // progress asset count
         this.total = 0;             // queue.length;
         this.assetsDict = {};       // reference asset img element list
         this.assetsRef = {};        // reference asset info list
 
         // create custom events
         this._onProgress = onProgress;
         this._progressEvent = new CustomEvent('asset_progress', {
             detail: { progress: () => this.count / this.total }
         });
         this._endEvent = new Event('asset_end');
 
         // init pre assets reference info
         let skipLoad = false;
         this.queued.forEach((assetItem) => 
         {
             const { name, ext, lazy, noexp } = assetItem;
             
             if (this.log) 
             {
                 console.log("[am].pre_init", lazy);
 
             }
             
             if (!lazy) skipLoad = true;
 
             this.assetsRef[name] = { 
                 name: name,         // String: Image Name
                 ext: ext,           // String: Image Ext
                 lazy: lazy,         // Bool: Allows to be lazy loaded
                 noexp: noexp,       // Bool: Sets Explicity Width and Height
                 size: null,         // Object: Image's native width and height
                 src: (size = "", fromImage = false) => 
                 {
                     if (size) size = `_${size}`;
                     const w = this.device.resolution().width;
                     if (w < 375) size = `_@0.2x`;
                     else if (w >= 375 && w < 500) size = `_@0.75x`;
                     else size = `_@1x`; // (w >= 500)
 
                     if (process.env.NODE_ENV === 'development') {
                         const { _is_record } = this.device;
                         if (_is_record) size = "";
                     }
 
                     const sc = this.assetFolder();
                     const ex = this.getImageExtFormat(ext);
                     const nU = withPrefix(`static/images/${path}/${sc}${name}${size}.${ex}`);
 
                     if (fromImage) {
                         // console.log("fromImage")
                         return this.get(name);
                     }
 
                     return nU;
                 }
             }
         });
 
         return skipLoad;
     }
 
     startImageLoad(lazyLoad = false)
     {
         if (this.log) 
         {
             console.log("[am].init_load", this.queued);
             console.log("[am].count", this.queued.length);
             console.log("[am].DICT", this.assetsDict);
         }
                 
         this.count = 0;
         this.total = this.queued.filter((item) => {
             
             // override lazyload asset prop
             if (!this.isLazy) return true;
             
             if (lazyLoad) return item.lazy;
             else return !item.lazy;
         }).length;
 
         if (this.log) console.log("[assets].total", this.total);
 
         return new Promise( (resolve, reject) => {
 
             this.queued.filter((item) => {
 
                 // override lazyload asset prop
                 if (!this.isLazy) return true;
 
                 if (lazyLoad) return item.lazy;
                 else return !item.lazy;
 
             }).forEach((assetItem) => 
             {
                 const { name } = assetItem;
                 const img = new Image();
 
                 img.src = this.assetsRef[name].src("");
                 img.onload = () => 
                 {
                     // when it finishes loading, update the component state
                     this.count++;
                     
                     window.dispatchEvent(this._progressEvent);
 
                     this.assetsDict[name] = img;
                     this.assetsRef[name].size = { w: img.naturalWidth, h: img.naturalHeight };
 
                     if (this.log) 
                     {
                         console.log("[assets].asset_count", this.count, "asset_loaded:", img.src);
                         console.log("[assets].percent", (this.count / this.total) * 100);
                     }
                     
                     // calculate progress and trigger onProgress
                     this.pCalc();
 
                     if (this.count === this.total)
                     {
                         this.loadComplete();
                         resolve(this.assetsDict);
                         window.dispatchEvent(this._endEvent);
                     }
                 }
                 img.onerror = (e) => {
 
                     //console.error("Error", e);
                     reject(e);
                 }
             });
         });
     }
 
     pCalc()
     {
         const ratio = (this.count / this.total);
         
         this._onProgress && this._onProgress({ 
             ratio: ratio,
             count: this.count, 
             total: this.total 
         });
     }
 
     loadComplete()
     {
        window.__app_assets[this.name] = this.assetsDict;
         
         if (this.log) console.log("[assets].load_complete", window.__app_assets);
     }
 
     get(assetKey) 
     {
         // console.log("GET.assetKey", assetKey);
 
         if (window.__app_assets[this.name][assetKey] !== undefined)
         {
             const el = window.__app_assets[this.name][assetKey];
             // console.log("GET.el", el);
             return el;
         }
             
         return null;
     }
 
     getRef(assetKey)
     {
        if (this.assetsRef === undefined) return;

         if (this.assetsRef[assetKey] !== undefined)
             return this.assetsRef[assetKey];
     }
 
     getImageExtFormat(orgExt)
     {
         if (process.env.NODE_ENV === 'development') {
             const { _is_record } = this.device;
             if (_is_record) return orgExt;
         }
 
         orgExt = this.device.isWebP() ? 'webp' : orgExt;
         return orgExt;
     }
 
     assetFolder = () => 
     {
         return "1x/";
     }
 
     purge()
     {
         // if (this.log) console.log("[assets].clear_assets", this.name);
         window.__app_assets[this.name] = [];
     }
 }