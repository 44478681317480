export const Pages = {
    HOME: "Home",
    ABOUT: "About",
    PHILOSOPHY: "Philosophy",
    REHABILITATION: "Rehabilitation",
    GALLERY: "Gallery",
    LOCALE: "Locale",
    CUISINE: "Cuisine",
    ACCOMODATIONS: "Accommodations",
    BOOK: "Book Your Stay",
    CONTACT: "Contact"
}

export const pageData = {
    [Pages.ABOUT]: {
        link: "/about",
    },
    [Pages.PHILOSOPHY]: {
        link: "/philosophy",
    },
    [Pages.REHABILITATION]: {
        link: "/rehabilitation",
    },
    [Pages.GALLERY]: {
        link: "/gallery",
    },
    [Pages.LOCALE]: {
        link: "/locale",
    },
    [Pages.CUISINE]: {
        link: "/cuisine",
    },
    [Pages.ACCOMODATIONS]: {
        link: "/accommodations",
    },
    [Pages.BOOK]: {
        link: "/bookyourstay",
    },
    [Pages.CONTACT]: {
        link: "/contact",
    }
}