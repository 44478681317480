/**
 * Author: Rafael Emmanuelli
 * 
 * BackgroundVideo
 * 
*/

import React, { Fragment, Component, createRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import VideoControls from "./controls/VideoControls";
import { videoResize } from "../../system/MiscTools";

export default class BackgroundVideo extends Component
{
    static propTypes = {
        device: PropTypes.any,                  // DeviceTool: get screenSize()
        videoRef: PropTypes.any,
        classes: PropTypes.string,
        videoClass: PropTypes.string,
        autoLoad: PropTypes.bool,
        autoSize: PropTypes.bool,
        isBackground: PropTypes.bool,
        autoPlay: PropTypes.bool,
        standBy: PropTypes.bool,
        loop: PropTypes.bool,
        delay: PropTypes.number,
        muted: PropTypes.bool,
        playsInline: PropTypes.bool,
        preload: PropTypes.string,
        scrnSize: PropTypes.bool,
        desktop: PropTypes.string,
        poster: PropTypes.string,
        onMounted: PropTypes.func,
        onProgress: PropTypes.func,
        onTime: PropTypes.func,
        onEnded: PropTypes.func,
        onData: PropTypes.func,
        onMeta: PropTypes.func,
        onDelay: PropTypes.func
    }
    
    static defaultProps = {
        classes: "",
        videoClass: "",
        autoLoad: true,
        autoSize: true,
        isBackground: false,
        standBy: false,
        autoPlay: true,
        loop: true,
        delay: -1,
        muted: true,
        playsInline: true,
        preload: "metadata",
        scrnSize: true,
        desktop: ""
    }

    constructor(props)
    {
        super(props);

        const { autoPlay, delay, scrnSize } = this.props;

        this.log = false;
        this.compRef = createRef();
        this.videoRef = createRef();
        this.controlsRef = (innerRef => this.controlsRef = innerRef);
        this.scrnSz = scrnSize;
        
        this.delay = delay > 0;
        this.delayStart = false;
        this.delayTm = null;

        this.state = {
            styles: {},
            isPlaying: autoPlay,
            hasEnded: false,
            delayedStart: false,
            progress: {
                videoEl: null,
                currentTime: 0,
                bufferedStart: 0,
                bufferedEnd: 0,
                durationTime: 0,
            }
        }

        this.onResize = this.onResize.bind(this);
        window.addEventListener("resize", this.onResize);
    }

    onResize(e)
    {
        const { autoSize, device} = this.props;

        if (autoSize && this.videoRef.current)
        {
            const styles = videoResize(this.videoRef.current, this.scrnSz, device);
            this.setState({styles});
        }
    }

    componentDidMount()
    {
        const { delay, videoRef, onMounted } = this.props;

        videoRef && videoRef(this.videoRef.current);
        onMounted && onMounted(this.videoRef.current);

        if (this.delay)
        {
            this.delayStart = true;
            this.delayTm = setTimeout(() => {
                this.setState( { delayedStart: true })
            }, delay * 1000)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        const { autoLoad } = this.props;

        if (autoLoad !== prevProps.autoLoad)
        {
            this.videoRef.current.setAttribute("webkit-playsInline", "");
        }
    }

    componentWillUnmount()
    {
        if (this.delayTm) clearTimeout(this.delayTm);
        window.removeEventListener("resize", this.onResize);
    }
    
    onVideoPlay(e)
    {
        const { isPlaying } = this.state;

        if (isPlaying) this.videoRef.current.pause();
        else this.videoRef.current.play();
    }

    onPlay(e)
    {
        this.setState({ isPlaying: true, hasEnded: false });
    }

    onPause(e)
    {
        this.setState({ isPlaying: false, hasEnded: false });
    }

    reset()
    {
        this.videoRef.current.pause();
        this.videoRef.current.currentTime = 0;
    }

    onMenu(event)
    {
        event.preventDefault();
        return false;
    }
    
    onMeta(e)
    {
        const { autoPlay, standBy, autoSize, onMeta, onDelay } = this.props;

        const vid = this.videoRef.current;

        if (autoSize) this.onResize();

        onMeta && onMeta({
            container: this.compRef,
            video: this.videoRef
        });

        if (autoPlay)
        {
            this.onPlay();
            vid.play();
        }

        // mobile issue when playing video goes blank then plays
        // as a workaround, force play then pause
        if (standBy)
        {
            vid.play();
            vid.pause();
        }

        onDelay && onDelay({ container: this.compRef });
    }
    
    onData(e)
    {
        const { onData } = this.props;

        onData && onData();
    }

    onProgress(e)
    {
        const { onProgress: pR } = this.props;

        pR && pR(e);
    }

    onTime(e)
    {
        const { onTime: tU } = this.props;
        const { current:videoEl } = this.videoRef;

        let buffSt = 0;
        let buffEnd = 0;

        if (!videoEl) return;

        if (videoEl.buffered.length > 0)
        {
            buffSt = videoEl.buffered.start(0);
            buffEnd = videoEl.buffered.end(0);
        }

        const progress = {
            videoEl: videoEl,
            currentTime: videoEl.currentTime,
            bufferedStart: buffSt,
            bufferedEnd: buffEnd,
            durationTime: videoEl.duration,
        }

        this.setState({ progress: progress });

        tU && tU(progress);
    }

    onEnded(e)
    {
        const { onEnded: end } = this.props;

        this.setState({ hasEnded: true });

        end && end();
    }

    render()
    {
        const { 
            device,
            classes,
            videoClass,
            isBackground,
            autoLoad,
            autoPlay,
            loop,
            preload,
            muted,
            playsInline,
            desktop,
            poster
        } = this.props;

        const { 
            styles, 
            isPlaying, 
            hasEnded, 
            progress,
            delayedStart 
        } = this.state;

        const compProps = { loop, autoPlay, preload, muted, playsInline }
        
        let source = desktop;
        let loadVideoAuto = autoLoad;

        // if we have a delay, disable autoPlay
        if (this.delay && !this.delayStart) 
        {
            compProps.autoPlay = false;
            loadVideoAuto = false;
        }
        else
        {
            if (delayedStart) loadVideoAuto = true;
        }

        return (
            <Fragment>
                {loadVideoAuto && <div ref={this.compRef} 
                    className={classNames("video-component", classes, 
                    { "is-background": isBackground }) 
                }>
                    {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                    <video 
                        ref={this.videoRef}
                        className={classNames("video-element", videoClass) }
                        style={styles}
                        poster={poster}

                        {...compProps}

                        onPlay={(e) => this.onPlay(e)}
                        onPause={(e) => this.onPause(e)}
                        onLoadedData={(e) => this.onData(e)}
                        onTimeUpdate={(e) => this.onTime(e)}
                        onEnded={(e) => this.onEnded(e)}
                        onLoadedMetadata={(e) => this.onMeta(e)}
                        onContextMenu={(e) => this.onMenu(e)}
                    >
                        <source type="video/mp4" src={source}/>
                    </video>

                    {!isBackground && <Fragment>

                        <VideoControls 
                            device={device}
                            controlsRef={this.controlsRef} 
                            progress={progress}
                            autoPlay={autoPlay} 
                            isPlaying={isPlaying} 
                            hasEnded={hasEnded} 
                            onPlayControl={() => this.onVideoPlay()}
                        >
                        </VideoControls>

                    </Fragment>}
                </div>}
                
            </Fragment>
        )
    }
}