/**
 * SEO component that queries for data with
 * 
 * Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

 import * as React from "react"
 import PropTypes from "prop-types"
 import { Helmet } from "react-helmet"
 import { useStaticQuery, graphql } from "gatsby"
 import { jsonSchema } from "../data/JSON_LD"

 function Seo({ page, title, description }) {
    
    const dt = new Date();
    const iso = dt.toISOString();

    const { site: { siteMetadata: meta } } = useStaticQuery(
        graphql`
        query {
            site {
                siteMetadata {
                    siteUrl
                    siteName
                    author
                    lang
                    locale
                    image {
                        url
                        width
                        height
                        alt
                        updatedTime
                    }
                    video {
                        url
                        width
                        height
                    }
                    social
                    facebook {
                        profile_id
                        app_id
                    }
                }
            }
        }`
    )
 
    const pageName = page === "home" ? "" : `${page}/`;
    const canonicalUrl = {
        rel: "canonical",
        href:  `${meta.siteUrl}/${pageName}`
    }
    const isTwitter = false;

    let metaData = [];
    metaData.push({ name: `description`, content: description });

    // open graph
    metaData.push({ property: `og:type`, content: `website` });
    metaData.push({ property: `og:url`, content: canonicalUrl.href });
    metaData.push({ property: `og:site_name`, content: meta.siteName });
    metaData.push({ property: `og:locale`, content: meta.locale });
    metaData.push({ property: `og:title`, content: title });
    metaData.push({ property: `og:description`, content: description });
    metaData.push({ property: `article:modified_time`, content: iso });

    // image tags
    if (meta.image)
    {
        metaData.push({ property: `og:image`, content: `${meta.image.url }` });
        metaData.push({ property: `og:width`, content: `${meta.image.width }` });
        metaData.push({ property: `og:height`, content: `${meta.image.height }` });
        metaData.push({ property: `og:alt`, content: `${meta.image.alt }` });
        metaData.push({ property: `og:updated_time`, content: `${meta.image.updatedTime }` });
    }

    // video tags
    if (meta.video)
    {
        metaData.push({ property: `og:video`, content: `${meta.video.url }` });
        metaData.push({ property: `og:video:width`, content: `${meta.video.width }` });
        metaData.push({ property: `og:video:height`, content: `${meta.video.height }` });
    }

    // social links
    if (meta.social && meta.social.length > 0)
    {
        //meta.social.forEach((socialLink) => {
        //    metaData.push({ property: `og:see_also`, content: `${socialLink}` });
        //})
    }

    // twitter tags
    if (isTwitter)
    {
        metaData.push({ name: `twitter:card`, content: `summary` });
        metaData.push({ name: `twitter:creator`, content: meta.author });
        metaData.push({ name: `twitter:title`, content: title });
        metaData.push({ name: `twitter:description`, content: description });
    }

    // accessibility
    // metaData.push({ itemprop: `accessibilityAPI`, content: `ARIA` });
    // metaData.push({ itemprop: `isFamilyFriendly`, content: `TRUE` });
    metaData.push({ itemprop: `accessibilityControl`, content: `fullKeyboardControl` });

    // create json+ld schema
    const jsonLdSchema = {
        id: "json_ld",
        type: "application/ld+json",
        innerHTML: JSON.stringify(jsonSchema)
    };

    return (
        <Helmet
            htmlAttributes={{ lang: meta.lang }}
            title={title}
            meta={metaData}
            link={[canonicalUrl]}
            script={[jsonLdSchema]}
        />
    )
}

Seo.propTypes = {
    page: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
}
 
export default Seo