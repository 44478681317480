/**
 * Author: Rafael Emmanuelli
 * 
 * Video Time Control
 * 
*/

import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";

import FullScreenIcon from "../../../../assets/fullscreen_icon.svg";

export default class VideoFullScreen extends Component
{
    static propTypes = {
        deviceType: PropTypes.string,
        videoEl: PropTypes.any
    }

    onClick() 
    {
        const { videoEl:el, deviceType: type } = this.props;

        if (el.webkitEnterFullscreen && type !== "desktop") el.webkitEnterFullscreen();
        else 
        {
            if (el.requestFullscreen) el.requestFullscreen();                       // Anything else
            else if (el.mozRequestFullScreen) el.mozRequestFullScreen();            // Firefox
            else if (el.webkitRequestFullscreen) el.webkitRequestFullscreen();    // Chrome, Safari & Opera
            else if (el.msRequestFullscreen) el.msRequestFullscreen();            // IE/Edge
        }
    }

    render()
    {
        return (
            <Fragment>
                <div 
                    role="button"
                    aria-label="Set to Fullscreen"
                    className="video-full-screen" 
                    onKeyPress={() => this.onClick()} 
                    onClick={() => this.onClick()} 
                    tabIndex={0}
                >
                    <FullScreenIcon />
                </div>
            </Fragment>
        )
    }
}