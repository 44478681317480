/**
 * Author: Rafael Emmanuelli
 * 
 * BackgroundAudio
 * 
 * 
*/

import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import gsap from "gsap";

export default class ExternalAudio extends PureComponent
{
    static propTypes = {
        device: PropTypes.any,
        audioSource: PropTypes.string,
        onLoadedMetadata: PropTypes.func
    }
    
    static defaultProps = {
        audioSource: null,
    }

    get audio() { return window.__raid.snd.el; }

    constructor(props)
    {
        super(props);

        this.fadeTween = null;
        this.isNewAudio = false;

        this.externalLib = null;

        this.state = {
            initSound: false
        }
    }

    componentDidMount()
    {
        this.externalLib = window.__raid.snd;
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        const { initSound } = this.state;

        if (!prevState.initSound && initSound)
        {
            this.playAudio();
        }
    }

    componentWillUnmount()
    {
        if (this.fadeTween) 
        {
            this.fadeTween.kill();
            this.fadeTween = null;
        }

        this.externalLib = null;
    }

    loadSound()
    {
        this.setState({ initSound: true });
    }

    isSoundLoaded()
    {
        return this.externalLib.isLoaded ? true : false;
    }
   
    playAudio()
    {
        const { device, audioSource } = this.props;

        if (!this.isSoundLoaded()) 
        {
            this.externalLib.loadSrc(audioSource);

            this.setState({ initSound: true });
            return;
        }
        
        const dur = device.isMobile() ? 0 : 2;

        gsap.set(this.audio, { volume: 0 } )

        this.audio.play();

        this.fadeTween = gsap.to(this.audio, { overwrite: "auto", volume: 1, duration: dur, ease: "linear" });
    }

    stopAudio()
    {
        this.fadeTween = gsap.to(this.audio, { overwrite: "auto", volume: 0, duration: 2, ease: "linear" , onComplete: () => {
            this.audio.pause();
        } })
    }

    isPlaying() { 
        if (!this.isSoundLoaded()) return false;
        
        return !this.audio.paused;
    }

    onLoadedMetadata(e)
    {
        const { onLoadedMetadata } = this.props;

        onLoadedMetadata && onLoadedMetadata();
    }

    render()
    {
        return (<Fragment></Fragment>)
    }
}