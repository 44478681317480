/**
 * Author: Rafael Emmanuelli / 11-26-2020
 * 
 * Font Loader
 * 
 * https://www.fontsquirrel.com/tools/webfont-generator
 * 
 * 
*/
import { withPrefix } from 'gatsby';

export default class FontLoad
{
    /**
     * Loads external font styles 
     * 
     * @param {Object} resolution 
     * @param {Number} resolution.width 
     * @param {Number} resolution.height
     */
    static load(resolution) 
    {
        if (resolution.width > 370) 
        {
            const id = '__raid_fonts';
            const tm = Date.now();
            if (!document.getElementById(id))
            {
                // console.log("loading.fonts", stage.w);
                const head  = document.getElementsByTagName('head')[0];
                const link  = document.createElement('link');
                link.id   = id;
                link.rel  = 'stylesheet';
                link.type = 'text/css';
                link.href = withPrefix(`static/css/fonts.css?t=${tm}`);
                head.appendChild(link);
            }
        }
    }
}