/**
 * Author: Rafael Emmanuelli / Insytive 01-01-2022
 * 
 * Page Animator
 * 
 * Handle in-page scroll events
 * 
*/
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger)

export default class PageAnimator
{
    constructor(props)
    {
        const { pageRef:ref, device } = props;

        this.timeline = null;

        this.ref = ref;
        this.device = device;
    }

    updateMedia(props)
    {
        const { sections } = props;

        sections.forEach((elem) =>
        {
            if (elem.hasAttribute("data-video"))
            {
                const video = elem.getElementsByClassName("video-element")[0];
                
                // video config 
                let videoSettings = JSON.parse(elem.getAttribute("data-video"));
                let start = videoSettings.offset ? videoSettings.offset.toString() : "0";
                let position = videoSettings.position ? videoSettings.position.toString() : "bottom center";
                
                // video config for phones?
                if (this.device.isPhone()) 
                {
                    if (videoSettings.phone)
                    {
                        start = videoSettings.phone.offset ? videoSettings.phone.offset.toString() : "0";
                        position = videoSettings.phone.position ? videoSettings.phone.position.toString() : "bottom center";
                    }
                }

                ScrollTrigger.create({
                    scroller: this.ref,
                    trigger: video,
                    markers: false,
                    start: `${position}+=${start}%`,
                    once: true,
                    onEnter: function() { 
                        video.play(); 
                    }, 
                });
            }
        });
    }

    /*updateHeader(props)
    {
        const { sections } = props;

        const header = document.getElementById("__header");

        console.log("hello");

        function updateHeader(elem)
        {
            const theme = elem.getAttribute("data-theme");
            header.setAttribute("data-theme", theme);
        }

        sections.forEach((elem) =>
        {
            if (elem.hasAttribute("data-theme"))
            {
                ScrollTrigger.create({
                    scroller: this.ref,
                    trigger: elem,
                    markers: false,
                    start: 'top 2%',
                    end: 'bottom 2%',
                    onEnter: function() { updateHeader(elem); }, 
                    onEnterBack: function() { updateHeader(elem); },
                });
            }

            //->let dataName = "";
            if (elem.hasAttribute("data-name"))
            {
                dataName = elem.getAttribute("data-name");

                ScrollTrigger.create({
                    scroller: document.getElementsByClassName("home-mobile")[0],
                    trigger: elem,
                    markers: false,
                    start: "top top", 
                    end: (window.innerHeight),
                    pin: true,
                    pinSpacing: false,
                    scrub: true,
                    anticipatePin: 100,
                });
            }--//
        });
    }
    */

    animateHero(props)
    {
        const { trigger } = props;

        const sOpts = {
            scroller: this.ref,
            start: "top",
            pin: true,
            pinSpacing: false, 
            anticipatePin: 1,
            pinType: "fixed"
        }

        const vidEl = trigger.getElementsByClassName('parallax');
        if (vidEl.length > 0)
        {
            ScrollTrigger.create({
                ...sOpts,
                trigger: vidEl[0]
            });
        }

        const imgEl = trigger.getElementsByClassName('bg-im');
        if (imgEl.length > 0) 
        {
            ScrollTrigger.create({
                ...sOpts,
                trigger: imgEl[0]
            });
        }
    }

    dispose()
    {
        this.ref = null;
        if (this.timeline) this.timeline.kill();
        this.timeline = null;
    }
}