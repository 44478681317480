export const homeData = () => 
{
    return [
        { id: "bb_1", name: "background_beach_1", ext: "png", lazy: true },
        { id: "bbw_1", name: "background_beach_water", ext: "png", lazy: true },
        { id: "bb_2", name: "background_beach_2", ext: "png", lazy: true },
        { id: "bb_3", name: "background_beach_3", ext: "png", lazy: true },
        { id: "bb_4a", name: "background_beach_4_a", ext: "png", lazy: true },
        { id: "bb_4b", name: "background_beach_4_b", ext: "jpg", lazy: true },
        { id: "exh_1", name: "exterior_house_1", ext: "png", lazy: true },
        { id: "hs_1", name: "house_sun", ext: "png", lazy: true },
        { id: "ep_1", name: "exterior_poolside_1", ext: "jpg", lazy: true },
        { id: "fs_1", name: "forest_scene", ext: "jpg", lazy: true },
        { id: "fsl_1", name: "forest_lady_scene", ext: "png", lazy: true },
        { id: "vf_1", name: "van_forest", ext: "jpg", lazy: true },
        { id: "bsg_1", name: "blue_slide_gradient", ext: "png", lazy: true },
        { id: "gsg_1", name: "green_slide_gradient", ext: "png", lazy: true },
        { id: "wsg_1", name: "white_slide_gradient", ext: "png", lazy: true },
        { id: "gsg_2", name: "grey_slide_gradient", ext: "png", lazy: true },
        { id: "lgos_1", name: "little_girl_on_surfboard", ext: "jpg", lazy: true },
        { id: "lgps_1", name: "little_girl_in_pool_surfing", ext: "jpg", lazy: true },
        { id: "lgs_1", name: "little_girls_swimming", ext: "jpg", lazy: true },
        { id: "nwcb_1", name: "nurse_with_child_beach", ext: "png", lazy: true },
        { id: "nwci_1", name: "nurse_with_child_indoor", ext: "png", lazy: true },
        { id: "csr_1", name: "couple_spa_relax", ext: "jpg", lazy: true },
        { id: "cbr_1", name: "couple_beach_relax", ext: "jpg", lazy: true },
        { id: "cr_1", name: "couple_romance", ext: "jpg", lazy: true },
        { id: "nh_1", name: "night_house", ext: "jpg", lazy: true },
        { id: "cw_1", name: "child_worker", ext: "jpg", lazy: true },
        { id: "fd_1", name: "family_dining", ext: "jpg", lazy: true },
        { id: "ow_1", name: "outro_wheelchair", ext: "jpg", lazy: true },
    ];
}

export const homeMobileData = () => 
{
    return [
        { id: "", name: "video_hero", ext: "jpg", lazy: false },
        { id: "", name: "couple_beach_shore", ext: "jpg", lazy: true },
        { id: "", name: "couple_spa", ext: "jpg", lazy: true },
        { id: "", name: "exterior_house", ext: "jpg", lazy: true },
        { id: "", name: "exterior_poolside", ext: "jpg", lazy: true },
        { id: "", name: "family_in_spa", ext: "jpg", lazy: true },
        { id: "", name: "family_lunch", ext: "jpg", lazy: true },
        { id: "", name: "forest_lady_shot", ext: "jpg", lazy: true },
        { id: "", name: "forest_lady_alpha", ext: "png", lazy: true },
        { id: "", name: "forest_pool_side", ext: "png", lazy: true },
        { id: "", name: "girl_ontop_board", ext: "jpg", lazy: true },
        { id: "", name: "girl_surf_beach", ext: "jpg", lazy: true },
        { id: "", name: "house_night_entrance", ext: "jpg", lazy: true },
        { id: "", name: "lady_wine", ext: "jpg", lazy: true },
        { id: "", name: "mountain_road", ext: "jpg", lazy: true },
        { id: "", name: "trainer_girl_indoor", ext: "jpg", lazy: true },
        { id: "", name: "trainer_girl_zoom", ext: "jpg", lazy: true },
        { id: "", name: "wheelchair_in_van", ext: "jpg", lazy: true },
        { id: "", name: "wheelchair_by_pool", ext: "jpg", lazy: true },
    ]
}

export const aboutData = () => 
{
    return [
        { id: "", name: "about_hero_shot", ext: "jpg", lazy: false },
        { id: "", name: "about_path_road", ext: "jpg", lazy: true },
        { id: "", name: "about_map", ext: "png", lazy: true },
        { id: "", name: "about_topview", ext: "jpg", lazy: true },
        { id: "", name: "about_master_bedroom", ext: "jpg", lazy: true },
        { id: "", name: "about_outside_hammock", ext: "jpg", lazy: true },
        { id: "", name: "trainers_working_patient", ext: "jpg", lazy: true },
        { id: "", name: "about_water_fall", ext: "jpg", lazy: true },
        { id: "", name: "about_night_house", ext: "jpg", lazy: true },
        { id: "", name: "about_waterfall_poolside", ext: "jpg", lazy: true },
        { id: "", name: "about_masterbed_fireplace", ext: "jpg", lazy: true },
    ]
}

export const philoData = () => 
{
    return [
        { id: "", name: "philo_hero_shot", ext: "jpg", lazy: false },
        { id: "", name: "ph_wheelchair_van", ext: "jpg", lazy: true },
        { id: "", name: "ph_kids_eating", ext: "jpg", lazy: true },
        { id: "", name: "ph_family_jacuzzi", ext: "jpg", lazy: true },
        { id: "", name: "ph_family_hallway", ext: "jpg", lazy: true },
        { id: "", name: "ph_casa_lupita_office", ext: "jpg", lazy: true },
        { id: "", name: "ph_lamp", ext: "jpg", lazy: true },
        { id: "", name: "ph_girl_pick_up", ext: "png", lazy: true },
        { id: "", name: "ph_girl_01", ext: "jpg", lazy: true },
        { id: "", name: "ph_girl_02", ext: "jpg", lazy: true },
    ]
}

export const rehabData = () => 
{
    return [
        { id: "", name: "rehab_hero_shot", ext: "jpg", lazy: false },
        { id: "", name: "worker_with_girl_on_beach", ext: "jpg", lazy: true },
        { id: "", name: "couple_looking_at_island", ext: "jpg", lazy: true },
        { id: "", name: "girl_creating_crafts", ext: "png", lazy: true },
        { id: "", name: "wheelchair_entering_van", ext: "jpg", lazy: true },
        { id: "", name: "trainers_working_with_girl", ext: "jpg", lazy: true },
        { id: "", name: "inside_main_office_area", ext: "jpg", lazy: true },
        { id: "", name: "trainer_adding_cast", ext: "jpg", lazy: true },
        { id: "", name: "trainers_girl_surf_pool", ext: "jpg", lazy: true },
        { id: "", name: "dining_table", ext: "jpg", lazy: true }
    ]
}

export const galleryData = () => 
{
    return [
        { id: "", name: "gallery_hero_shot", ext: "jpg", lazy: false }
    ]
}

export const localeData = () => 
{
    return [
        { id: "", name: "locale_hero_shot", ext: "jpg", lazy: false },
        { id: "", name: "locale_map", ext: "jpg", lazy: true },
        { id: "", name: "locale_top_view_island", ext: "jpg", lazy: true },
        { id: "", name: "locale_top_view_shore", ext: "jpg", lazy: true },
        { id: "", name: "locale_top_view_edge", ext: "jpg", lazy: true },
        { id: "", name: "locale_sir_francis", ext: "jpg", lazy: true },
        { id: "", name: "locale_pirate_ships", ext: "jpg", lazy: true },
        { id: "", name: "locale_francis_signature", ext: "jpg", lazy: true }
    ]
}

export const cuisineData = () => 
{
    return [
        { id: "", name: "cuisine_hero_shot", ext: "jpg", lazy: false },
        { id: "", name: "assorted_tacos_on_wooden_plate", ext: "png", lazy: true, noexp: true },
        { id: "", name: "cooked_tacos", ext: "jpg", lazy: true },
        { id: "", name: "silver_spoon_on_black_bowl", ext: "png", lazy: true, noexp: true },
        { id: "", name: "luxury_dinner_table", ext: "jpg", lazy: true },
        { id: "", name: "clear_glass_goblet", ext: "jpg", lazy: true },
        { id: "", name: "assorted_sliced_citrus_fruit", ext: "png", lazy: true, noexp: true },
        { id: "", name: "exterior_balcony_table", ext: "jpg", lazy: true },
        { id: "", name: "assorted_pies_and_tarts_plate", ext: "jpg", lazy: true },
        { id: "", name: "assorted_vegatables", ext: "jpg", lazy: true }
    ]
}

export const accoData = () => 
{
    return [
        { id: "", name: "accomodations_hero_shot", ext: "jpg", lazy: false },
        { id: "", name: "bedroom_01_1", ext: "jpg", lazy: true },
        { id: "", name: "bedroom_01_bath_1", ext: "jpg", lazy: true },
        { id: "", name: "bedroom_02_1", ext: "jpg", lazy: true },
        { id: "", name: "bedroom_02_bath_1", ext: "jpg", lazy: true },
        { id: "", name: "bedroom_03_1", ext: "jpg", lazy: true },
        { id: "", name: "bedroom_03_2", ext: "jpg", lazy: true },
        { id: "", name: "bedroom_03_bath_1", ext: "jpg", lazy: true },
        { id: "", name: "bedroom_03_bath_2", ext: "jpg", lazy: true },
        { id: "", name: "bedroom_03_bath_3", ext: "jpg", lazy: true },
        { id: "", name: "indoor_ameneties_1", ext: "jpg", lazy: true },
        { id: "", name: "master_01_1", ext: "jpg", lazy: true },
        { id: "", name: "master_01_2", ext: "jpg", lazy: true },
        { id: "", name: "master_bath_01_1", ext: "jpg", lazy: true },
        { id: "", name: "night_pool_1", ext: "jpg", lazy: true },
        { id: "", name: "outdoor_ameneties_1", ext: "jpg", lazy: true },
        { id: "", name: "outdoor_ameneties_2", ext: "jpg", lazy: true },
        { id: "", name: "outdoor_ameneties_3", ext: "jpg", lazy: true },
        { id: "", name: "outdoor_ameneties_4", ext: "jpg", lazy: true }
    ]
}

export const bookStayData = () => 
{
    return [
        { id: "", name: "book_hero_shot", ext: "jpg", lazy: false },
        { id: "", name: "my_huatulco_vacation", ext: "jpg", lazy: true, noexp: true },
        { id: "", name: "bk_casa_lupita_front", ext: "jpg", lazy: true },
        { id: "", name: "bk_family_pool", ext: "jpg", lazy: true }
    ]
}

export const contactData = () => 
{
    return [
        { id: "", name: "contact_hero_shot", ext: "jpg", lazy: false },
        { id: "", name: "contact_top_view_casa", ext: "jpg", lazy: true }
    ]
}