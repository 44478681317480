
/**
 * Author: Rafael Emmanuelli
 * 
 * Misc/Support Tools
 * 
*/

/**
 * 
 * @param {HTMLElement} el Element that has the animation
 * @returns {String} Returns platform's event name for CSS Animation End
 */
function animationEndEvent(el) 
{
    let t;
    let animations = { "animation": "animationend", "OAnimation": "oAnimationEnd", "MozAnimation": "animationend", "WebkitAnimation": "webkitAnimationEnd" }
    for (t in animations) { if (el.style[t] !== undefined) { return animations[t];} }
}

/**
 * calculate scale proportional to device screen size
 * 
 * @param {Number} naturalWidth 
 * @param {Number} naturalHeight 
 * @param {Boolean} scrnSz 
 * @returns {Object}
 */
function setScaledSize(naturalWidth, naturalHeight, scrnSz) 
{
    let screenWidth = window.innerWidth;
    let screenHeight = window.innerHeight;

    // use screen size?
    if (scrnSz)
    {
        const actualWidth = (screenWidth > screenHeight) ? window.screen.height : window.screen.width;
        const actualHeight = (screenWidth > screenHeight) ? window.screen.width : window.screen.height;

        screenWidth = actualWidth;
        screenHeight = actualHeight;
    }

    let newWidth = 0, newHeight = 0, newRatio = 0;
    let landscape = screenWidth > screenHeight;

    const videoRatio = naturalHeight / naturalWidth;
    const windowRatio = screenHeight / screenWidth;
    
    // same size
    if (windowRatio === videoRatio) {
        newWidth = screenWidth;
        newHeight = screenHeight;
    }
    else 
    {
        if (windowRatio > videoRatio)
        {
            newRatio = screenHeight / naturalHeight;

            newWidth = naturalWidth * newRatio;
            newHeight = naturalHeight * newRatio;
        }
        else 
        {
            newRatio = screenWidth / naturalWidth;

            if (screenWidth === naturalWidth) 
            {
                newWidth = naturalWidth;
                newHeight = naturalHeight;
            }
            else if (screenWidth < naturalWidth)  
            {
                newRatio = screenWidth / naturalWidth;
                newWidth = naturalWidth * newRatio;
                newHeight = naturalHeight * newRatio;
            }
            else 
            {
                newWidth = naturalWidth * newRatio;
                newHeight = naturalHeight * newRatio;
            }
        }
    }

    return {
        isLandscape: landscape,
        width: Math.floor(newWidth),
        height: Math.floor(newHeight)
    }
}

/**
 * 
 * @param {HTMLElement} video HTML5 Video element
 * @param {Boolean} scrnSz Do we use the full screen size
 * @param {Function} device Returns width and height [DeviceTool]
 * @returns 
 */
const Alignment = { ALIGN_LEFT: "left", ALIGN_CENTER: "center", ALIGN_RIGHT: "right" }

function videoResize(video, scrnSz, device) // HTML Video Element
{
    let styles = { width: "", height: "", margin: "" };

    /* For background full screen resizing   */

    // calculate video size
    const newVideoSize = setScaledSize(video.videoWidth, video.videoHeight, scrnSz);

    // calculate size
    // let res = device.resolution();
    let res = device; // get current device resolution

    // use screen size?
    if (scrnSz)
    {
        // Note: Screen object does not reflect orientation. 
        // So we are checking if landscape from uarDevice 
        res = {
            width: (res.width > res.height) ? window.screen.height : window.screen.width,
            height: (res.width > res.height) ? window.screen.width : window.screen.height
        }
    }

    // calculate margins
    const widthDiff = Math.abs(res.width - newVideoSize.width);
    const heightDiff = Math.abs(res.height - newVideoSize.height);

    styles.width = newVideoSize.width + "px";
    styles.height = newVideoSize.height + "px";

    const videoAlignment = { align: Alignment.ALIGN_CENTER }
    const alignmentPadding = { x: widthDiff / 2, y: heightDiff / 2 }

    let videoAlignMargin = "";
    let marginTop = alignmentPadding.y;
    let marginRight = alignmentPadding.x;

    if (newVideoSize.isLandscape)
    {
        switch (videoAlignment.align)
        {
            case Alignment.ALIGN_CENTER:
                videoAlignMargin = "-" + marginTop + "px 0px 0px -" + (marginRight) + "px";
            break;

            case Alignment.ALIGN_LEFT:
                videoAlignMargin = marginTop + "px 0 0 0";
            break;

            case Alignment.ALIGN_RIGHT:
                videoAlignMargin = marginTop + "px 0px 0px -" + (marginRight) + "px";
            break;
            default:
        }

        styles.margin = videoAlignMargin;
    }
    else 
    {
        switch (videoAlignment.align)
        {
            case Alignment.ALIGN_CENTER:
                videoAlignMargin = "-" + marginTop + "px 0px 0px -" + (marginRight) + "px";
            break;

            case Alignment.ALIGN_LEFT:
                videoAlignMargin = marginTop + "px 0 0 0";
            break;

            case Alignment.ALIGN_RIGHT:
                videoAlignMargin = marginTop + "px 0px 0px -" + (newVideoSize.width - marginRight) + "px";
            break;
            default:
        }

        styles.margin = videoAlignMargin;
    }

    return styles;
}

/**
 * 
 * @param {Number} time Current time in milliseconds
 * @returns {Object} 
 */
function secondsToTime(time)
{
    let minutes = Math.floor(time / 60);
    let seconds = Math.floor(time - minutes * 60);

    let minuteLabel = minutes, secondLabel = seconds;

    if ( minutes <= 9 ) { minuteLabel = "0" + minutes; }
    if ( seconds <= 9 ) { secondLabel = "0" + seconds; }

    return {
        minute: minuteLabel,
        seconds: secondLabel,
        display: minuteLabel + ":" + secondLabel,
        rawMinute: minutes,
        rawSecond: seconds,
    }
}

/**
 * 
 * @param {Number} curTime Current time in milliseconds
 * @param {Number} durTime Duration time in milliseconds
 * @returns {String}
 */
function curTimeDurText(curTime, durTime)
{
    const cT = secondsToTime(curTime);
    const dT = secondsToTime(durTime);

    let min = cT.rawMinute + " Minute";
    min += (cT.rawMinute > 1) ? "s" : "";
    
    let sec = cT.rawSecond + " Second";
    sec += (cT.rawSecond > 1) ? "s" : "";
    
    let minDur = dT.rawMinute + " Minute";
    minDur += (dT.rawMinute > 1) ? "s" : "";
    
    let secDur = dT.rawSecond + " Second";
    secDur += (dT.rawSecond > 1) ? "s" : "";

    return `${min} ${sec} of ${minDur} ${secDur}`
}

/**
 * 
 * @param {Number} curTime Current time in milliseconds
 * @returns {String}
 */
function curTimeText(curTime)
{
    const cT = secondsToTime(curTime);
    
    let min = cT.rawMinute + " Minute";
    min += (cT.rawMinute > 1) ? "s" : "";
    
    let sec = cT.rawSecond + " Second";
    sec += (cT.rawSecond > 1) ? "s" : "";
    
    return `${min} ${sec}`
}

module.exports = {
     animationEndEvent,
    setScaledSize,
    videoResize,
    secondsToTime,
    curTimeDurText,
    curTimeText
}