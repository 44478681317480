import React, { createRef, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import gsap from "gsap";
import BaseOverlay from "./BaseOverlay";
import BackgroundVideo from "../media/BackgroundVideo";
import CloseIcon from "../../../assets/close_icon.svg";
import { withPrefix } from 'gatsby';

export default class ExperienceVideoOverlay extends BaseOverlay
{
    static propTypes = {
        device: PropTypes.any.isRequired,                       // DeviceTool: get screenSize()
        onClose: PropTypes.func,
        // onVideoShow: PropTypes.func,
        // onVideoHide: PropTypes.func,
        // onVideoEnded: PropTypes.func
    }

    constructor(props)
    {
        super(props);

        this.overlayRef = createRef();
        this.stageRef = createRef();    
        this.vidCRef = createRef();                                         // Video Container
        this.closeRef = createRef();                                        // Video Close Button
        this.videoRef = (innerRef => this.videoRef = innerRef);             // HTML Video Element
        this.barSz = { width: 0, height: 0 }                                // Video Stage Bar Size

        this.onClose = this.onClose.bind(this);
        this.onVidEnd = this.onVidEnd.bind(this);
       
        this.tl = null;     
        this.pEase = "power1.out";

        this.closeEvent = new Event('exp_video_close');

        this.state = {
            initVideo: true,
            showVideo: false,
            videoHeight: 0
        }
    }

    componentDidMount()
    {
        this.tl = gsap.timeline({ paused: false });
        this.tl.to(this.overlayRef.current, { opacity: 1, duration: 0.75, ease: this.pEase, onComplete: () => 
        {
            this.setState({ showVideo: true });

        } }, "-=0");
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        const { showVideo } = this.state;

        this.updateVideoSize();

        if (!prevState.showVideo && showVideo)
        {
            this.showVideoBars();

            // enable focus control
            this.setFocus({ el: this.overlayRef.current, isGuided: true, noHead: true, ignoreEsc: false });
        }
    }

    updateVideoSize()
    {
        const rect = this.videoRef.getBoundingClientRect();

        this.barSz.width = rect.left;
        this.barSz.height = rect.top;

        if (this.state.showVideo)
        {
            const bars = gsap.utils.toArray(this.stageRef.current.getElementsByClassName("video-bar"));
            gsap.set(bars[1], { width: this.barSz.width });
            gsap.set(bars[3], { width: this.barSz.width });
            gsap.set(bars[0], { height: this.barSz.height });
            gsap.set(bars[2], { height: this.barSz.height });

            const cover = this.stageRef.current.getElementsByClassName("video-cover");
            gsap.set(cover, { width: rect.width, height: rect.height, top: rect.top });
        }
    }

    showVideoBars()
    {
        //const { onVideoShow } = this.props;
        //onVideoShow && onVideoShow();

        const bars = gsap.utils.toArray(this.stageRef.current.getElementsByClassName("video-bar"));
        const cover = this.stageRef.current.getElementsByClassName("video-cover");
        const frame = this.stageRef.current.getElementsByClassName("video-frame");

        gsap.set(this.closeRef.current, { opacity: 0, scale: 0.5 });
        gsap.set(bars[1], { width: 0 });
        gsap.set(bars[3], { width: 0 });
        gsap.set(bars[0], { height: 0 });
        gsap.set(bars[2], { height: 0 });
        gsap.set(bars, { opacity: 1 });
        gsap.set(cover, { autoAlpha: 0, scale: 0.5 });

        const scene = gsap.timeline({ id: "showVideo", paused: false });
        scene.to(bars[1], { width: this.barSz.width, duration: 1, ease: this.pEase }, "-=0");
        scene.to(bars[3], { width: this.barSz.width, duration: 1, ease: this.pEase }, "<");
        scene.to(bars[0], { height: this.barSz.height, duration: 1, ease: this.pEase }, "<");
        scene.to(bars[2], { height: this.barSz.height, duration: 1, ease: this.pEase }, "<");
        scene.to(cover, { autoAlpha: 1, scale: 1, duration: 1, ease: "power1.inOut" }, "<");
        scene.to(cover, { autoAlpha: 0, duration: 0.85, ease: "linear" }, "+=0.25");
        scene.to(frame, { autoAlpha: 1, duration: 0, ease: "none" }, "<");
        scene.to(this.overlayRef.current, { backgroundColor: "rgba(0, 0, 0, 1)", duration: 0, ease: "none" }, "<");
        scene.to(this.closeRef.current, { scale: 1, opacity: 1, duration: 0.85, ease: "power1.inOut", onComplete: () => {
            this.videoRef.play();
        } }, "-=0");
    }

    hideVideoBars()
    {
        const { onClose } = this.props;

        if (this.stageRef.current === null) return;

        const bars = gsap.utils.toArray(this.stageRef.current.getElementsByClassName("video-bar"));
        const cover = this.stageRef.current.getElementsByClassName("video-cover");
        const frame = this.stageRef.current.getElementsByClassName("video-frame");

        const scene = gsap.timeline({ id: "hideVideo", paused: false });
        scene.to(this.closeRef.current, { scale: 0, opacity: 0, ease: this.pEase }, "-=0");
        scene.to(this.overlayRef.current, { backgroundColor: "rgba(0, 0, 0, 0.4)", duration: 0, ease: "none" }, "<");
        scene.to(cover, { autoAlpha: 1, duration: 0.65, ease: this.pEase }, "<");
        scene.to(frame, { autoAlpha: 0, duration: 0, ease: "none" }, ">");
        scene.to(cover, { opacity: 0, duration: 0.65, ease: "linear" }, ">");
        scene.to(bars[1], { width: 0, duration: 1, ease: this.pEase }, ">-0.5");
        scene.to(bars[3], { width: 0, duration: 1, ease: this.pEase }, "<");
        scene.to(bars[0], { height: 0, duration: 1, ease: this.pEase }, "<");

        scene.to(bars[2], { height: 0, duration: 1, ease: this.pEase, onComplete: () => {

            this.videoRef.currentTime = 0;

            gsap.to(this.overlayRef.current, { opacity: 0, duration: 0.75, ease: this.pEase, onComplete: () => 
            {
                window.dispatchEvent(this.closeEvent);
                this.setState({ showVideo: false });
                onClose && onClose();
            } })


        } }, "<");
    }

    onVidEnd()
    {
        window.__raid.ga.trackEvent('Website', 'Video Ended');
        this.hideVideoBars(true);
    }

    onClose()
    {
        this.videoRef.pause();
        this.hideVideoBars();
    }

    closeFocus()
    {
        this.onClose();
    }


    onResize()
    {
        this.updateVideoSize();
    }

    dispose()
    {
        if (this.tl) this.tl.kill();

        this.closeEvent = null;
    }

    render()
    {
        const { device, baseClassName } = this.props;
        const { initVideo, showVideo, videoHeight } = this.state;

        let videoContainerStyles = {};
        if (videoHeight) videoContainerStyles.height = videoHeight;

        return <Fragment>
            <div ref={this.overlayRef} 
                role="dialog" 
                aria-label="Experience Video Modal" 
                className={classNames(baseClassName, "video-overlay", "black")}
            >
                <div className="overlay-container">
                    {initVideo && <div ref={this.stageRef} className={classNames("video-stage", [{ "is-show": showVideo }] )}>
                        <div className="video-bar top"></div>
                        <div className="video-bar left"></div>
                        <div className="video-bar bottom"></div>
                        <div className="video-bar right"></div>
                        <div ref={this.vidCRef} className="video-container" style={videoContainerStyles}>
                            <div className="video-inner-container">
                                <div className="video-frame">
                                    <BackgroundVideo 
                                        device={device}
                                        desktop={withPrefix("static/videos/casa_lupita_video.mp4")}
                                        videoRef={this.videoRef}
                                        autoPlay={false}
                                        autoSize={false}
                                        loop={false} 
                                        muted={false}
                                        onEnded={this.onVidEnd} 
                                    />
                                </div>
                                <div className="video-cover"></div>
                            </div>
                        </div>
                        <button 
                            ref={this.closeRef} 
                            aria-label="Select to close video overlay"
                            className="close-btn rounded" 
                            onClick={this.onClose}
                        >
                            <CloseIcon/>
                        </button>
                    </div>}
                </div>
            </div>
        </Fragment>
    }
}