import * as React from "react"
import { Component, cloneElement, createRef } from "react"
import { withPrefix } from 'gatsby';
import PropTypes from "prop-types"
import Header from "./header"
import DeviceTool from "../system/DeviceTool"
import AssetManager from "../system/AssetManager"
import OverlayManager from "./OverlayManager"
import FontLoad from "../system/FontLoad"
import ExpVideoOverlay from "../components/overlay/ExperienceVideoOverlay"
import ScrollTrigger from "gsap/ScrollTrigger"
  
export default class PageManager extends Component 
{
    static propTypes = {
        page: PropTypes.string.isRequired,
        data: PropTypes.object.isRequired,
        windowLocation: PropTypes.object.isRequired,
        children: PropTypes.node.isRequired,
    }

    state = { 
        showMenu: false,
        hasResized: false,
        hasLoaded: false
    }

    get asset() { return this.assetManager }
    get overlay() { return this.overlayRef.current }
    get header() { return this.headerRef.current }

    constructor(props)
    {
        super(props);

        this.isSSR = typeof window === "undefined";

        this.headerRef = createRef();
        this.overlayRef = createRef();
        this.curRef = createRef();

        this.device = new DeviceTool({ is_rec: false });
        this.assetManager = new AssetManager({ log: false, device: this.device });
        this.stage = { w: 0, h: 0 };

        // menu props
        this.isMenuClicked = false;
        this.handleVideo = this.handleVideo.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.viewMenuEvt = null;
        this.closeMenuEvt = null;
    }

    componentDidMount()
    {
        const { windowLocation:location } = this.props;

        // setup events
        this.viewMenuEvt = new Event('view_menu');
        this.closeMenuEvt = new Event('close_menu');

        // load fonts for subpages only
        const isHome = location.pathname === withPrefix("/");
        if (!isHome) FontLoad.load(this.device.resolution());

        // handle resize
        if (!this.isSSR)
        {
            document.body.style.overflowY = "";
            window.addEventListener('view_video', this.handleVideo, false);
            window.addEventListener("resize", this.handleResize, false);
        }
    }

    componentWillUnmount()
    {
        if (!this.isSSR) 
        {
            window.removeEventListener("resize", this.handleResize);
            window.removeEventListener('view_video', this.handleVideo, false);
        }
    }

    showHeader()
    {
        this.header.show();
        this.header.enable(false, false);
    }
    
    hideHeader()
    {
        this.header.hide();
        this.header.enable(true, true);
    }

     /*
        NOTE: toggleMenuPanel controls MenuOverlay and MenuButton states

        noTransition forces the menu to close without transition
    */
    toggleMenuPanel(isMenuOpen, noTransition = false)
    {
        if (this.isMenuClicked) return;
        this.isMenuClicked = true;

        if (!isMenuOpen) 
        {
            FontLoad.load(this.device.resolution());

            if (!this.isSSR) window.__raid.ga.trackEvent('Website', "Menu");

            // lock scroll, toggle menu button and open overlay
            this.lockScroll(true);
            this.header.toggleMenuIcon(!isMenuOpen);
            this.setState({ showMenu: true });

            if (!this.isSSR) window.dispatchEvent(this.viewMenuEvt);
        }
        else 
        {
            // unlock scroll, toggle menu button and close overlay
            this.lockScroll(false);
            this.header.toggleMenuIcon(!isMenuOpen);
            
            if (noTransition) 
            {
                this.isMenuClicked = false;
                this.setState({ showMenu: false })
            }
            else this.overlay.hideMenu();

            if (!this.isSSR) window.dispatchEvent(this.closeMenuEvt);
        }
    }

    /*
        Triggered when Menu Overlay transition is complete
    */
    onMenuShow(isMenuOpen, withKey)
    {
        const isMenu = !isMenuOpen;
        
        this.isMenuClicked = false;

        if (withKey) this.toggleMenuPanel(isMenu, true)
        else 
        {
            if (isMenu) this.setState({ showMenu: false });
        }
    }

    lockScroll(enable)
    {
        // only for home page, we are adding/removing overscroll style
        const { windowLocation:location } = this.props;
        const isHome = location.pathname === withPrefix("/");

        if (enable) 
        {
            if (isHome) document.body.style.overflowY = "";
            document.body.classList.add("lock");
        }
        else 
        {
            if (isHome) document.body.style.overflowY = "scroll";
            if (!document.body.classList.contains("fixed")) document.body.classList.remove("lock");
        }
    }

    fixedScroll(enable = false)
    {
        if (!enable) document.body.classList.remove("fixed");
        else document.body.classList.add("fixed");
    }

    handleVideo()
    {
        this.hideHeader();

        if (this.header.audio.isPlaying()) this.header.audio.stopAudio();

        if (!this.isSSR) 
            window.__raid.ga.trackEvent('Website', 'Played Video');

        this.overlayRef.current.addOverlay(
            <ExpVideoOverlay
                device={this.device.screenSize}
                onClose={() => this.onVideoClose()}
        />, "video");
    }

    onVideoClose()
    {
        if (!this.isSSR) 
            window.removeEventListener('view_video', this.onLaunchVideo, false);
        
        this.showHeader();
        this.overlayRef.current.removeOverlay("video");

        if (!this.header.audio.isPlaying()) this.header.audio.playAudio();
    }

    pageReset()
    {
        this.scrollReset();
        document.body.style.overflowY = "";
        this.overlay.removeAll();
        this.toggleMenuPanel(true, true);
        this.fixedScroll(false);
        this.lockScroll(false);
        this.isMenuClicked = false;
    }

    scrollReset()
    {
        ScrollTrigger.getAll().forEach((stInt) => {
            stInt.kill();
        })

        const stInstance = ScrollTrigger.getById("mainScroller");
        if (stInstance) stInstance.kill();

        ScrollTrigger.update();
        ScrollTrigger.refresh(true);
    }

    handleResize()
    {
        this.device.initialize();

        this.overlayRef && this.overlay.onResize(this.device);
        const { hasResized:hRz } = this.state;
        this.setState({ hasResized: !hRz })
    }

    render()
    {
        const { data, windowLocation, children } = this.props;
        const { showMenu, hasResized: hRz } = this.state;

        const location = { 
            origin: data.site.siteMetadata?.siteUrl,
            pathname: windowLocation.pathname,
            details: windowLocation
        };

        return (
            <>
                <Header 
                    ref={this.headerRef} 
                    location={location} 
                    device={this.device} 
                    onMenuClick={(isOpen, key) => this.toggleMenuPanel(isOpen, key)}
                />
                <main id="__pageManager">{
                    children.map((child, index) => {
                        if (index === 1) // 0 - SEO, 1 - Page
                            return cloneElement(child, { key: index, owner: this, hasResized: hRz, location: location })

                        return cloneElement(child, { key: index });
                    })
                }</main>
                <OverlayManager
                    ref={this.overlayRef} 
                    location={location} 
                    owner={this}
                    showMenu={showMenu} 
                    onMenuShow={(menu, key) => this.onMenuShow(menu, key)}
                />
            </>
        )
    }
}