/**
 *
 * BasePage
 * 
*/

import { Component, createRef } from "react"
import PropTypes from "prop-types";
import PageAnimator from "../animation/PageAnimator";
import { withPrefix } from 'gatsby';

export default class BasePage extends Component
{
    asset(key) { return this._owner.asset.get(key); } 
    assetRef(key) { return this._owner.asset.getRef(key); } 
    
    static propTypes = {
        owner: PropTypes.object,
        hasResized: PropTypes.bool,
        location: PropTypes.object,
    }

    constructor(props)
    {
        super(props);

        this.isBrowser = typeof window !== "undefined";

        const { owner } = props;

        this._owner = owner;

        this._pageRef = null;
        this._pageLoaderRef = createRef();
        this._scrollRef = createRef();
        
        // video load delay
        this._delay = 3.5; 

        // enable/disable lazy load
        this._assetPath = "";
        this._assets = null;
        this._isLazy = false;
        
        this._pageAnimator = null;
        this._pageTween = null;
        this._delayTween = null;
        this._pageTimeline = null;
        
        this._isMove = false;
        this._clicked = false;
        
        // handle events
        this.onCloseVideo = this.onCloseVideo.bind(this);
        this.menuViewed = this.menuViewed.bind(this);
        this.menuClosed = this.menuClosed.bind(this);
    }

    withPrefix(file)
    {
        return withPrefix(file);
    }

    menuViewed() {}

    menuClosed() {}

    showScroll()
    {
        this._scrollRef.current.classList.add("show-scroll");
    }

    componentDidMount()
    {
        this._device = this._owner.device;
        this._devSize = this._device.screenSize;
        this._isPhone = this.props.owner.device.isPhone();

        // enable/disable lazy load
        this._isLazy = this._owner.isLazyLoadEnabled;

        this._pageAnimator = new PageAnimator({ pageRef: this._pageRef, device: this._owner.device });

        this._viewVideoEvent = new Event('view_video');
        window.addEventListener("view_menu", this.menuViewed);
        window.addEventListener("close_menu", this.menuClosed);
    }

    afterPageLoad()
    {
        const bgr = [].slice.call(this._pageRef.getElementsByTagName("section"));

        this._pageAnimator.animateHero({
            trigger: bgr[0],
            bgLayer: "video-component",
        });

        this._pageAnimator.updateMedia({
            sections: bgr
        });
        
        /*(this._pageAnimator.updateHeader({
            sections: bgr
        });*/
        /*this._pageAnimator.animateImage({
            imageLayerClass: "m-image-layer"
        })
        this._pageAnimator.animateText({
            textLayerClass: "m-text-layer"
        })*/
    }

    // props = { isLazy: false, onAssetEnd: null }
    startAssets(settings)
    {
        // console.log("BasePage.startAssets", this._assets)

        const { isLazy, onAssetEnd:aEnd } = settings;

        if (this._assets.length === 0) 
        {
            this.onAssetEnd();
            return;
        }

        const skipLoad = this._owner.assetManager.initialize({
            name: "assets",
            queue: this._assets, // assets,
            isLazy: this._isLazy,
            path: this._assetPath,
        });

        // skip loading if we don't have anything to load
        if (skipLoad) {
            this.onAssetEnd();
            return;
        }

        // override with global lazyload enabler
        const ez = this._isLazy ? isLazy : this._isLazy;
        // console.log("startAssets.lazyLoad", enableLazyLoad)

        this._owner.assetManager.startImageLoad(ez).then(() => 
        {
            aEnd && aEnd();
        });
    }

    onAssetEnd() {}

    initPage()
    {
        this._owner.showHeader();

        this._isMove = false;
        
        this.touchStart = this.touchStart.bind(this);

        window.addEventListener("touchstart", this.touchStart);
        window.addEventListener("touchmove", this.touchStart);
        window.addEventListener("touchend", this.touchStart);
        
        if (this._owner.device.isDesktop())
        {
            window.addEventListener("mousemove", this.touchStart);
            window.addEventListener("wheel", this.touchStart);
        }
                
    }

    touchStart(e)
    {
        this.removeIntEvents();

        this.onInteraction();
    }

    removeIntEvents()
    {
        window.removeEventListener("mousemove", this.touchStart)
        window.removeEventListener("touchstart", this.touchStart);
        window.removeEventListener("touchmove", this.touchStart);
        window.removeEventListener("touchend", this.touchStart);
        window.removeEventListener("wheel", this.touchStart);
    }

    onInteraction()
    {
        if (this._isMove) return;
        this._isMove = true;

        this.setState({ hasLazyLoaded: true });
    }

    startAssetOverlay()
    {
        if (!this._isLazy) 
        {
            this.setState({ hasLazyLoaded: true });
            return;
        }
    }

    startLazyLoad()
    {
        // start initial asset loading...
        this._owner.assetManager.startImageLoad(true).then((imagesLoaded) => 
        {
            // this.onLazyAssetEnd();
        });
    }

    /*onLazyAssetEnd() 
    {
        // console.log("onLazyAssetEnd");

        // this.setState({ hasLazyLoaded: true });
    }*/

    onViewVideo()
    {
        if (this._clicked) return;
        this._clicked = true;
        window.dispatchEvent(this._viewVideoEvent);
        window.addEventListener("exp_video_close", this.onCloseVideo);
    }

    onCloseVideo()
    {
        window.removeEventListener("exp_video_close", this.onCloseVideo);

        this._clicked = false;
    }

    shouldComponentUpdate(nextProps, nextState)
    {
        const { hasResized:hRz } = this.props;

        if (hRz !== nextProps.hasResized)
        {
            this.updateLayout();
        }

        return true;
    }

    updateLayout()
    {
        this._devSize = this._device.screenSize;
    }
    
    componentWillUnmount()
    {
        window.removeEventListener("exp_video_close", this.onCloseVideo);

        this.removeIntEvents();

        this.dispose();
    }

    pVideoNam(name)
    {
        if (this._owner === undefined) return;

        const { device } = this._owner;

        const w = device.resolution().width;
        const phone = device.isPhone();
        const ultra = w > 2000;
        const low = w <= 370;
        const vidP = "static/videos";
        let mobP = phone ? "/mobile" : "/hd";
        if (low) mobP = "/low";
        if (ultra) mobP = "/ultra";

        return withPrefix(`${vidP}${mobP}/${name}.mp4`);
    }

    dispose()
    {
        window.removeEventListener("view_menu", this.menuViewed);
        window.removeEventListener("close_menu", this.menuClosed);

        this._pageAnimator.dispose();
        this._pageAnimator = null;

        this._owner = null;
        this._pageRef = null;
        this._pageLoaderRef = null;

        if (this._pageTimeline) this._pageTimeline.kill();
        if (this._delayTween) this._delayTween.kill();
        if (this._pageTween) this._pageTween.kill();

        this._pageTimeline = null;
        this._pageTween = null;
    }
}