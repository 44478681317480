/**
 * Author: Rafael Emmanuelli
 * 
 * Hamburger Menu Button
 * 
*/

import React, { Fragment, Component, createRef } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { pageData } from "../data/Pages";

export default class MenuButton extends Component
{
    static propTypes = {
        location: PropTypes.object,
        onClick: PropTypes.func,
    }
    
    constructor(props)
    {
        super(props);

        this.btnR = createRef();
        this.menuIR = createRef();

        this.onInput = this.onInput.bind(this);

        this.state = {
            isOpen: false
        }
    }

    onInput(e)
    {
        const { isOpen } = this.state;
        const { onClick } = this.props;

        onClick && onClick(isOpen, false);
    }

    toggleMenu(isOpen)
    {
        this.setState({ isOpen: isOpen });
    }

    render()
    {
        const { location } = this.props;
        const { isOpen } = this.state;

        const w3 = "http://www.w3.org/2000/svg";
        const vb = "0 0 44 44";
        const d = "M22,22 L2,22 C2,11 11,2 22,2 C33,2 42,11 42,22";

        return (
            <Fragment>
                <div ref={this.btnR} 
                    role="button"
                    aria-label="Menu Button"
                    aria-expanded={isOpen ? "true" : "false"}
                    aria-haspopup={true}
                    tabIndex={0}
                    className={classNames("menu-icon-button", { "is-open": isOpen })}
                    onKeyPress={this.onInput}
                >
                    <label className="menu-toggle">
                        
                        <div className={classNames("menu-icon-label", { "is-open": isOpen })}>Menu</div>
                        <input ref={this.menuIR} checked={isOpen} type="checkbox" aria-hidden={true} onChange={this.onInput}></input>
                        <div className="menu-icon-holder" aria-hidden="true">
                            <div className="menu-cross"><span></span><span></span></div>
                            <svg className="menu-svg-1" xmlns={w3} viewBox={vb}><path d={d}></path></svg>
                            <svg className="menu-svg-2" xmlns={w3} viewBox={vb}><path d={d}></path></svg>
                        </div>
                    </label>
                    
                    <div className="x-menu-list" aria-hidden={true}>
                        {Object.entries(pageData).map((item, index) => {
                            const name = item[0];       
                            const { link } = item[1];
                            //console.log("link", link)
                            //console.log("location", location)
                            const href = `${location.origin}${link}/`;
                            return <a key={index} tabIndex={-1} href={href}>{name}</a>
                        })}
                    </div>

                </div>
            </Fragment>
        )
    }
}