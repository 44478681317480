/**
 * Author: Rafael Emmanuelli / Insytive 09-03-2020
 * 
 * Footer
 * 
*/

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withPrefix } from 'gatsby';

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

import CALogo from "../../assets/footer_casa_lupita_logo.svg";
import YTIcon from "../../assets/youtube_icon.svg";
import IGIcon from "../../assets/instagram_icon.svg";
import FBIcon from "../../assets/facebook_icon.svg";

gsap.registerPlugin(MotionPathPlugin);

export default class Footer extends Component
{
    static propTypes = {
        style: PropTypes.object,
        startTime: PropTypes.number,
        owner: PropTypes.object,
        pageRef: PropTypes.object,
        isIntro: PropTypes.bool,
        hasLazyLoaded: PropTypes.bool,
        onViewVideo: PropTypes.func,
        onLoad: PropTypes.func,
    }
    
    static defaultProps = {
        startTime: 0,
        isIntro: false,
        hasLazyLoaded: false,
    }

    componentDidUpdate(prevProps, prevState)
    {
        const { hasLazyLoaded:lz } = this.props;

        if (lz !== prevProps.hasLazyLoaded)
        {
            //console.log("Footer.componentDidUpdate()");
            this.onLogoLoad();
        }

    }

    onLogoLoad()
    {
        const { isIntro, onLoad } = this.props;

        if (!isIntro) this.initTween();

        onLoad && onLoad();
    }

    initTween()
    {
        const { pageRef } = this.props;

        const scene = this.createTimeline();
        scene.timeScale(2);
        const fS = pageRef.getElementsByClassName("m-footer")[0];

        ScrollTrigger.create({
            scroller: pageRef,
            trigger: fS,
            markers: false,
            scrub: false,
            start: 'top 100%',
            end: "bottom bottom",
            animation: scene,
            toggleActions: "play none none none"
        });
    }

    createTimeline()
    {
        const { startTime, owner, pageRef, isIntro } = this.props; // startTime = 1 for intro 

        const scene = gsap.timeline({ id: "footer", paused: isIntro ? false : true });
        const durPath = 2, logoDur = 1;

        const fS = pageRef.getElementsByClassName("m-footer")[0];
        const inF = fS.querySelector(".inner-footer");
        const yellowP = inF.querySelector(".mp2");
        const yellowC = inF.querySelector(".yellow-c");
        const redP = inF.querySelector(".mp1");
        const redC = inF.querySelector(".red-c");
        const blueP = inF.querySelector(".mp3");
        const blueC = inF.querySelector(".blue-c")
        const logoP = gsap.utils.toArray(inF.getElementsByClassName("logo-part"));
        const logoW = inF.querySelector(".logo-w");
        const logoT = inF.querySelector(".logo-text");
        const logoST = inF.querySelector(".logo-stext");
        
        let scroll = null;
        if (isIntro) scroll = owner.scrollIconRef.current.innerRef;

        scene.addLabel("end_logo", `-=0`);
        scene.call(() => 
        {
            // fast scrolling, prevent from showing scroll icon when in video overlay mode
            if (isIntro) gsap.to(scroll, { autoAlpha: 1, ease: "power1.out", duration: 0.35 })
        }, null, `end_logo+=${0}`);

        if (isIntro) scene.from(fS, { duration: 4, ease: "linear", x: "0%", y: "30%" }, `end_logo`);

        scene.from(blueC, { autoAlpha: 0, ease: "linear", duration: durPath }, `end_logo+=${startTime}`);
        scene.to(blueC, { duration: durPath, ease: "power1.inOut", 
            motionPath:{ 
                path: blueP, 
                align: blueP,
                autoRotate: true,
                alignOrigin: [0.5, 0.5],
                start: 1,
                end: 0
            }
        }, `end_logo`);

        scene.from(redC, { autoAlpha: 0, ease: "linear", duration: durPath }, `end_logo+=${1.5}`);
        scene.to(redC, { duration: durPath, ease: "power1.inOut", 
            motionPath:{ 
                path: redP, 
                align: redP,
                autoRotate: true,
                alignOrigin: [0.5, 0.5],
                start: 1,
                end: 0
            }
        }, `end_logo+=${1}`);
        
        scene.from(yellowC, { autoAlpha: 0, ease: "linear", duration: durPath }, `end_logo+=${2}`);
        scene.to(yellowC, { duration: durPath, ease: "power1.inOut", 
            motionPath:{ 
                path: yellowP, 
                align: yellowP,
                autoRotate: true,
                alignOrigin: [0.5, 0.5],
                start: 1,
                end: 0
            }
        }, `end_logo+=${2}`);

        scene.addLabel("logo_parts", `+=${durPath - 1.95}`);
        scene.from([logoP[0], logoP[1]], { duration: logoDur, ease: "linear", scale: 1.1, opacity: 0, stagger: 0.15 }, `logo_parts`);
        scene.from(logoP[0], { duration: logoDur, ease: "linear", x: "-40%", y: "2%" }, `logo_parts`);
        scene.from(logoP[1], { duration: logoDur, ease: "linear", x: "40%", y: "2%" }, `logo_parts`);
        scene.from(logoP[2], { duration: logoDur, ease: "linear", opacity: 0 }, `logo_parts+=${0.25}`);
        scene.from(logoW, { duration: logoDur, ease: "linear", opacity: 0 }, `logo_parts+=${0.25}`);
        scene.addLabel("logo_text", `logo_parts+=${logoDur * 1}`);
        scene.from(logoT, { duration: logoDur, ease: "linear", opacity: 0 }, `logo_text`);
        scene.from(logoST, { duration: logoDur, ease: "linear", opacity: 0 }, `logo_text+=0.25`);

        scene.call(() => 
        {
            // fast scrolling, prevent from showing scroll icon when in video overlay mode
            if (isIntro) gsap.to(scroll, { autoAlpha: 0, ease: "power1.out", duration: 0.35 })
        }, null, `end_logo+=${2}`);

        return scene;
    }

    render()
    {
        const { style, isIntro, hasLazyLoaded:lz, owner } = this.props;
        const { props: { location } } = owner;

        const pLink = withPrefix(`partials/email.php?n=2`);
        const emailLink = `${location.origin}${pLink}`;

        return (
            <Fragment>
                <div className="inner-footer" style={style}>
                    <div className="footer-row">
                        <div className="footer-col c-logo">
                            {((!isIntro && lz) || isIntro) && <CALogo />}
                        </div>
                    </div>
                    
                    <div className="footer-row">
                        <div className="footer-col">
                            <ul className="footer-list">
                                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
                                <li className="footer-title" tabIndex={0}>Contacts</li>
                                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
                                <li className="footer-item" tabIndex={0}>House: 011 52 9586881036</li>
                                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
                                <li className="footer-item" tabIndex={0}>Office: 011 52 9585872643</li>
                                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
                                <li className="footer-item" tabIndex={0}><a href={emailLink} rel="nofollow">Email Us</a></li>
                            </ul>
                        </div>
                        
                        <div className="footer-col">
                            <ul className="footer-list">
                                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
                                <li className="footer-title" tabIndex={0}>Address</li>
                                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
                                <li className="footer-item" tabIndex={0}>Primera cerrada de Copalita, mza 4, lot 10</li>
                                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
                                <li className="footer-item" tabIndex={0}>La Bocana de Copalita, Santa Maria Huatulco</li>
                                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
                                <li className="footer-item" tabIndex={0}>Oaxaca, Mex C.P. 70988</li>
                            </ul>
                        </div>

                        <div className="footer-col video-col">
                            <ul className="footer-list">
                                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
                                <li className="footer-title" tabIndex={0}>Media</li>
                                <li className="footer-item view-video">
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <a href="#" rel="noopener" onClick={(e) => {
                                        e.preventDefault();
                                        const { owner } = this.props;
                                        owner.onViewVideo();
                                    }}>Play Intro Video</a>
                                </li>
                            </ul>
                        </div>
                        
                        <div className="footer-col social-panel">
                            <ul className="footer-list social-list">
                                <li className="footer-item social">
                                    <a href="https://www.facebook.com/CasaLupitaHux" aria-label="Facebook" rel="noopener">
                                        {((!isIntro && lz) || isIntro) && <FBIcon />}
                                        {(!isIntro && !lz) && <>Fb</>}
                                    </a>
                                </li>
                                <li className="footer-item social">
                                    <a href="https://www.instagram.com/CasaLupitaHux" aria-label="Instagram" rel="noopener">
                                        {((!isIntro && lz) || isIntro) && <IGIcon />}
                                        {(!isIntro && !lz) && <>Ig</>}
                                    </a>
                                </li>
                                <li className="footer-item social">
                                    <a href="https://www.youtube.com/channel/UCA_aJ8oJuki2X1y52q9jJjw" aria-label="Youtube" rel="noopener">
                                        {((!isIntro && lz) || isIntro) && <YTIcon />}
                                        {(!isIntro && !lz) && <>Yt</>}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <hr className="footer-line"></hr>
                    <div className="footer-row">
                        <div className="footer-col copy-col">
                            <div className="copyright">© {new Date().getFullYear()} Casa Lupita. All Rights Reserved.</div>
                            <div className="site-by"><a href="http://insytive.com/" aria-label="Site by Insytive" rel="noopener">Site by Insytive</a></div>
                        </div>
                    </div>
                </div>

            </Fragment>
        )
    }
}