/**
 * Author: Rafael Emmanuelli
 * 
 * Video Time Control
 * 
*/

import React, { Fragment, Component, createRef } from "react";
import PropTypes from "prop-types";

import PlayIcon from "../../../../assets/play_icon.svg";
import PauseIcon from "../../../../assets/pause_icon.svg";
import ReplayIcon from "../../../../assets/replay_icon.svg";

import VideoProgressBar from "./VideoProgressBar";
import VideoTime from "./VideoTime";
import VideoFullScreen from "./VideoFullScreen";

export default class VideoControls extends Component
{
    static propTypes = {
        device: PropTypes.any.isRequired,               // DeviceTool: get screenSize()
        controlsRef: PropTypes.any,
        isPlaying: PropTypes.bool,
        hasEnded: PropTypes.bool,
        progress: PropTypes.shape({
            videoEl: PropTypes.any,
            currentTime: PropTypes.number, 
            durationTime: PropTypes.number,
            bufferedStart: PropTypes.number,            
            bufferedEnd: PropTypes.number 
        }),
        onPlayControl: PropTypes.func
    }

    static defaultProps = {
        controlsRef: null,
        isPlaying: false,
        hasEnded: false,
        progress: {
            currentTime: 0,
            bufferedStart: 0,
            bufferedEnd: 0,
            durationTime: 0,
        }
    }
    
    constructor(props)
    {
        super(props);

        this.ctrlRef = createRef();
        this.hasInit = false;
        this.isShowing = false;
        this.timer = null;

        this.onTabFocus = this.onTabFocus.bind(this);
        this.noHide = false;
    }

    componentDidMount()
    {
        const { controlsRef, isPlaying } = this.props;

        // avoid error on hot reload
        if (typeof controlsRef !== 'function') return;

        controlsRef && controlsRef(this.ctrlRef.current);

        // initially show controls for some time...
        this.timer = setTimeout(() => { this.onTimerExp(); }, 8000);

        if (isPlaying) this.onClick();

        window.addEventListener('focus_tab', this.onTabFocus);
    }

    onTabFocus()
    {
        window.addEventListener('focus_tab', this.onTabFocus);

        this.noHide = true;
        clearTimeout(this.timer);
    }

    componentWillUnmount()
    {
        clearTimeout(this.timer);
    }

    onClick()
    {
        const { onPlayControl } = this.props;

        onPlayControl && onPlayControl();
    }

    onEnter(e)
    {
        if (!this.hasInit) return;

        clearTimeout(this.timer);

        this.isShowing = true;
        this.ctrlRef.current.classList.add("show-controls");

        this.setTimer();
    }
    
    onLeave(e)
    {
        if (!this.hasInit) return;

        clearTimeout(this.timer);
        
        if (this.noHide) return;

        this.isShowing = false;
        this.ctrlRef.current.classList.remove("show-controls");
    }
    
    onMove(e)
    {
        if (!this.hasInit) return;

        if (!this.ctrlRef.current.classList.contains("show-controls"))
        {
            clearTimeout(this.timer);

            this.ctrlRef.current.classList.add("show-controls");
            if (!this.noHide) this.setTimer();
        }
    }

    setTimer()
    {
        this.timer = setTimeout(() => { this.onLeave(); }, 3000);
    }

    onTimerExp()
    {
        if (!this.hasInit) this.hasInit = true;

        if (this.noHide) return;

        this.ctrlRef.current.classList.remove("show-controls");
    }

    render()
    {
        const { device, progress, isPlaying, hasEnded } = this.props;

        return (
            <Fragment>
                <div
                    className="video-controls"
                    role="toolbar"
                    onMouseLeave={(e) => this.onLeave(e)}
                    onMouseEnter={(e) => this.onEnter(e)}
                    onMouseMove={(e) => this.onMove(e)}
                >
                    <div ref={this.ctrlRef} className="bottom-controls show-controls">
                        <button 
                            className="video-btn play-control icon" 
                            onClick={(e) => this.onClick(e)} 
                            aria-label="Play video" 
                            tabIndex={0}
                        >
                            {(!isPlaying && !hasEnded) && <PlayIcon className="play-icon"/>}
                            {(isPlaying && !hasEnded) && <PauseIcon className="pause-icon"/>}
                            {(!isPlaying && hasEnded) && <ReplayIcon className="replay-icon"/>}
                        </button>
                        <VideoProgressBar {...progress} device={device} />
                        <VideoFullScreen videoEl={progress.videoEl} deviceType={device.type} />
                        <VideoTime {...progress} />
                    </div>
                </div>
            </Fragment>
        )
    }
}