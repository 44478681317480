export const jsonSchema = {
    "@context": "http://schema.org",
    "@graph": [
      {
        "@type": "WebPage",
        "author": {
          "@id": "https://casalupita.net/#identity"
        },
        "copyrightHolder": {
          "@id": "https://casalupita.net/#identity"
        },
        "copyrightYear": "2022",
        "creator": {
          "@id": "https://casalupita.net/#creator"
        },
        "dateModified": "2022-01-30T23:19:48.391Z",
        "datePublished": "2022-01-30T23:19:48.391Z",
        "description": "Welcome to “Rehabilitation Redefined.” Casa Lupita, set in a tropical paradise nestled in the hills of the Pacific Coast of Oaxaca, in the Bahia’s de Huatulco, Mexico is a fully handicap accessible, state-of-the-art facility offering 5-star luxury accommodations.",
        "headline": "Fully handicap accessible.  Rehabilitation Center specializing in pediatrics as well as physical and mental health trauma, wellness recovery for the whole family. Casa Lupita",
        "image": {
          "@type": "ImageObject",
          "url": "https://casalupita.net/images/site/casa_logo_seo_1200x630.jpg?mtime=1643584788391"
        },
        "inLanguage": "en",
        "mainEntityOfPage": "https://casalupita.net/",
        "name": "Casa Lupita",
        "publisher": {
          "@id": "https://casalupita.net/#creator"
        },
        "url": "https://casalupita.net"
      },
      {
        "@id": "https://casalupita.net/#identity",
        "@type": "Organization",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Primera cerrada de Copalita, mza 4, lot 10",
          "addressCountry": "MX",
          "addressLocality": "Oaxaca",
          "addressRegion": "La Bocana de Copalita, Santa Maria Huatulco",
          "postalCode": "70988"
        },
        "alternateName": "casalupita",
        "description": "Welcome to “Rehabilitation Redefined.” Casa Lupita, set in a tropical paradise nestled in the hills of the Pacific Coast of Oaxaca, in the Bahia’s de Huatulco, Mexico is a fully handicap accessible, state-of-the-art facility offering 5-star luxury accommodations.",
        "email": "gerardo@casalupita.com",
        "founder": "Dr. Andrew Glassman",
        "foundingDate": "2006-01-01",
        "foundingLocation": "Oaxaca, Huatulco, MX",
        "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "011 52 9585872643",
          "contactType": "General Manager"
        },
        "image": {
          "@type": "ImageObject",
          "height": "2048",
          "url": "https://casalupita.net/images/site/casa_logo_seo_2048x2048.png?mtime=1643584788391",
          "width": "2048"
        },
        "logo": {
          "@type": "ImageObject",
          "height": "60",
          "url": "https://casalupita.net/images/site/casa_logo_seo_60x60.png?mtime=1643584788391",
          "width": "60"
        },
        "name": "Casa Lupita",
        "sameAs": [
          "https://www.facebook.com/CasaLupitaHux",
          "https://www.instagram.com/CasaLupitaHux",
          "https://www.youtube.com/channel/UCA_aJ8oJuki2X1y52q9jJjw"
        ],
        "url": "https://casalupita.net"
      },
      {
        "@id": "https://casalupita.net/#creator",
        "@type": "Organization",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Primera cerrada de Copalita, mza 4, lot 10",
          "addressCountry": "MX",
          "addressLocality": "Oaxaca",
          "addressRegion": "La Bocana de Copalita, Santa Maria Huatulco",
          "postalCode": "70988"
        },
        "alternateName": "casalupita",
        "description": "Welcome to “Rehabilitation Redefined.” Casa Lupita, set in a tropical paradise nestled in the hills of the Pacific Coast of Oaxaca, in the Bahia’s de Huatulco, Mexico is a fully handicap accessible, state-of-the-art facility offering 5-star luxury accommodations.",
        "email": "gerardo@casalupita.com",
        "founder": "Dr. Andrew Glassman",
        "foundingDate": "2006-01-01",
        "foundingLocation": "Oaxaca, Huatulco, MX",
        "image": {
          "@type": "ImageObject",
          "height": "1042",
          "url": "https://casalupita.net/images/site/casa_logo_seo_1042x1042.png?mtime=1643584788391",
          "width": "1042"
        },
        "logo": {
          "@type": "ImageObject",
          "height": "60",
          "url": "https://casalupita.net/images/site/casa_logo_seo_60x60.png?mtime=1643584788391",
          "width": "60"
        },
        "name": "Casa Lupita",
        "url": "https://casalupita.net"
      },
      {
        "@type": "ItemList",
        "itemListElement": [
          {
            "@type": "VideoObject",
            "position": 1,
            "name": "Casa Lupita Rehabilitation Redefined",
            "description": "A new concept in luxury rehabilitation, there´s a place where time stands still, are transformed, renewed, inclusive, accessible and unforgettable.",
            "contentUrl": "https://www.youtube.com/watch?v=4Tz_1OIa69E",
            "embedUrl": "https://www.youtube.com/watch?v=4Tz_1OIa69E",
            "thumbnailUrl": "https://img.youtube.com/vi/4Tz_1OIa69E/mqdefault.jpg",
            "uploadDate": "2022-01-30T23:19:48.391Z"
          }
        ]
      },
      {
        "@type": "BreadcrumbList",
        "name": "Breadcrumbs",
        "description": "Breadcrumbs list",
        "itemListElement": [
          {
            "@type": "ListItem",
            "item": "https://casalupita.net",
            "name": "Homepage",
            "position": 1
          },
          {
            "@type": "ListItem",
            "item": "https://casalupita.net/about",
            "name": "About",
            "position": 2
          },
          {
            "@type": "ListItem",
            "item": "https://casalupita.net/philosophy",
            "name": "Philosophy",
            "position": 3
          },
          {
            "@type": "ListItem",
            "item": "https://casalupita.net/rehabilitation",
            "name": "Rehabilitation",
            "position": 4
          },
          {
            "@type": "ListItem",
            "item": "https://casalupita.net/gallery",
            "name": "Gallery",
            "position": 4
          },
          {
            "@type": "ListItem",
            "item": "https://casalupita.net/locale",
            "name": "Locale",
            "position": 5
          },
          {
            "@type": "ListItem",
            "item": "https://casalupita.net/cuisine",
            "name": "Cuisine",
            "position": 6
          },
          {
            "@type": "ListItem",
            "item": "https://casalupita.net/accommodations",
            "name": "Accommodations",
            "position": 7
          },
          {
            "@type": "ListItem",
            "item": "https://casalupita.net/bookyourstay",
            "name": "Book Your Stay",
            "position": 8
          },
          {
            "@type": "ListItem",
            "item": "https://casalupita.net/contact",
            "name": "Contact",
            "position": 9
          }
        ]
      }
    ]
}