/**
 * Author: Rafael Emmanuelli / Insytive 12-28-2021
 * 
 * Module
 * 
*/

import React, { Component, Fragment, createRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export default class Module extends Component
{
    static propTypes = {
        device: PropTypes.any.isRequired,
        classes: PropTypes.string,
        isHeader: PropTypes.bool,
        isFullSize: PropTypes.bool,
        hasRichLayout: PropTypes.bool,
        name: PropTypes.string,
        theme: PropTypes.string,
        animate: PropTypes.string,
        backgroundColor: PropTypes.string,
        hasLazyLoaded: PropTypes.bool,
        hasResized: PropTypes.bool,
        hasUpdated: PropTypes.bool,
    }
    
    static defaultProps = {
        classes: "",
        isHeader: false,
        hasRichLayout: false,
        theme: "light",
        backgroundColor: "white",
        hasLazyLoaded: false,
        hasResized: false,
        hasUpdated: false,
    }

    constructor(props)
    {
        super(props);

        this.moduleRef = createRef();
    }

    shouldComponentUpdate(nextProps, nextState)
    {
        const { children:ch, hasLazyLoaded:lz, hasResized:rz, hasUpdated:hu } = this.props;
        
        if (hu !== nextProps.hasUpdated) return true;
        if (rz !== nextProps.hasResized) return true;
        if (lz !== nextProps.hasLazyLoaded) return true;
        if (ch.length === nextProps.children.length) return false;

        return true;
    }

    render()
    {
        const { 
            device,
            classes,
            children:ch, 
            isHeader:isH,
            isFullSize:isFull,
            hasRichLayout:hasRh,
            theme,
            name,
            animate,
            backgroundColor,
        } = this.props;

        const styles = {
            backgroundColor
        }

        if (isH) styles.height = device.height + "px";

        const classItems = classNames("module", classes, {
            "module-header": isH,
            "module-full-size": isFull,
            "module-richlayout": hasRh
        })

        let _preCh = [];
        if (Array.isArray(ch)) _preCh = ch; else _preCh = [ch];
        
        const layers = _preCh.map((layer, index) => {
            return React.cloneElement(layer, { key: index, ...layer.props, device });
        });

        return (
            <Fragment>
                <section 
                    className={classItems} 
                    style={styles} 
                    data-type={hasRh ? "richLayout" : null}
                    data-theme={theme}
                    data-name={name}
                    data-animate={animate}
                >
                    {isH && <div className="fixed-bg">{layers}</div>}
                    {!isH && layers}
                </section>
            </Fragment>
        )
    }
}