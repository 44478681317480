import React, { createRef, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import gsap from "gsap";
import BaseOverlay from "./BaseOverlay";
import { pageData } from "../../data/Pages";
import { animationEndEvent } from "../../system/MiscTools";
// import { Link } from "gatsby";
import Link from "../gatsby/Link";

export default class MenuOverlay extends BaseOverlay
{
    static propTypes = {
        pageMan: PropTypes.any.isRequired,          // Page Manager
        location: PropTypes.object,
        onTransitionComplete: PropTypes.func,
        onHideMenu: PropTypes.func,
    }
    
    constructor(props)
    {
        super(props);

        this.overlayRef = createRef();
        this.menuItemsRef = createRef();
        this.seqTimeline = null;
        this.closedWithKey = false;

        this.animEndName = "";
        this.onAnimStartEnd = this.onAnimStartEnd.bind(this);
        this.onAnimEnd = this.onAnimEnd.bind(this);
    }

    componentDidMount()
    {
        this.menuItems = [].slice.call(this.menuItemsRef.current.getElementsByClassName("menu-item"));

        this.animEndName = animationEndEvent(this.overlayRef.current);
        this.overlayRef.current.addEventListener(this.animEndName, this.onAnimStartEnd);
        
        if (this.seqTimeline) this.seqTimeline.kill();
        this.seqTimeline = gsap.timeline({ paused: false });
        this.seqTimeline.from(this.menuItems, { delay: 0.5, duration: 0.95, opacity: 0, x: "-30%", stagger: 0.15, ease: "power4.out" }, "+=0");

        // enable focus control
        this.setFocus({ el: this.overlayRef.current });
    }

    dispose()
    {
        super.dispose();
    }

    transitionOut()
    {
        if (!this.overlayRef) return;

        this.overlayRef.current.classList.add("close");
        this.overlayRef.current.addEventListener(this.animEndName, this.onAnimEnd);
    }

    onAnimStartEnd()
    {
        const { onTransitionComplete } = this.props;
        
        this.overlayRef.current.removeEventListener(this.animEndName, this.onAnimStartEnd);

        onTransitionComplete && onTransitionComplete(true);
    }

    onAnimEnd()
    {
        const { onTransitionComplete } = this.props;

        this.overlayRef.current.removeEventListener(this.animEndName, this.onAnimEnd);

        onTransitionComplete && onTransitionComplete(false, this.closedWithKey);
    }

    /*
        Triggered when user closes overlay with ESC key
    */
    closeFocus()
    {
        this.closedWithKey = true;
        const { onHideMenu } = this.props;
        onHideMenu && onHideMenu(this.closedWithKey);
    }

    render()
    {
        const { baseClassName, pageMan, location } = this.props; 

        return <Fragment>

            <div 
                ref={this.overlayRef} 
                role="dialog" 
                aria-label="Menu Panel" 
                className={classNames(baseClassName, "menu-overlay")}
            >
                <div className="overlay-container">
                    <ul ref={this.menuItemsRef} className="menu-items">

                        {Object.entries(pageData).map((item, index) => {

                            const name = item[0];                            
                            const { link } = item[1];
                            
                            const href = `${location.origin}${link}/`;

                            return (<li key={index} className="menu-item" role="none">
                        
                                <Link 
                                    role="menuitem"
                                    tabIndex={0}
                                    className="menu-link"
                                    activeClassName="active"
                                    partiallyActive={false}
                                    onClick={(e) => {
                                        pageMan.pageReset();
                                        if (!pageMan.header.audio.isPlaying()) pageMan.header.audio.playAudio();
                                    }} 
                                    to={href} 
                                >
                                    <div className="inner-item">
                                        <div className="menu-label">{name}</div>
                                    </div>
                                </Link>
                            
                            </li>)

                        })}
                       
                    </ul>
                </div>
            </div>

        </Fragment>
    }
}