/**
 * Author: Rafael Emmanuelli / 11-26-2020
 * 
 * Device Detection
 * 
 */
 export default class DeviceTool
 {
     constructor(props)
     {
        this.isSSR = typeof window === "undefined";

         if (process.env.NODE_ENV === 'development') {
             const { is_rec } = props;
             this._is_record = is_rec;
             if (this._is_record) document.body.classList.add("recording");
         }
 
         // device info object
         this.device = {};
 
         // ipad resolutions
         this.iPadScreens = ['1024x1366', '1366x1024', '834x1194', '1194x834', '834x1112', '1112x834', '768x1024', '1024x768', '1024x1292', '1292x1024', '1366x921', '1366x954', '1024x1263', '1024x1296', '1024x1327'];
         
         this.initialize();
     }
 
     initialize()
     {
         // remove previous classes to html
         if (!this.isSSR) this.removeHtmlClasses();
 
         // reset device info
         this.device = { ios: false, android: false, type: false, browser: false, resolution: false, orientation: false, touch: false, pointer: false };
 
         // init user agent and platform
         const navigator = this.getNavigatorInstance();
         this.ua = (navigator) ? navigator.userAgent : false;  // USE navigator.userAgentData
         // if (!this.ua) { console.warn("[device_tool] no user agent found"); return; }
         if (!this.ua) return;
         
         this.platform = navigator.platform;
         
         //console.log("navigator.userAgent", navigator.userAgent)
         //console.log("navigator.userAgentData", navigator.userAgentData)
 
         // mobile devices ie: tablet/phone 
         // NOTE: Problems with original detection on FireFox iOS
         this.android = !!this.ua.match(/(Android);?[\s]+([\d.]+)?/);
         this.ipad = this.ua.match(/iPad/g);
 
         // iPad Pro models report MacIntel instead of iOS iPad, check for touch / MacIntel
         if (!this.ipad) this.ipad = (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0);
         this.ipod = this.ua.match(/iPod/g);
         this.iphone = !this.ipad && this.ua.match(/iPhone/g);
 
         // desktop platforms
         this.windows = this.platform === 'Win32';
         this.macos = this.platform === 'MacIntel';
 
         // check if we are on iPad Tablet
         this.pointer = !!window.PointerEvent && ('maxTouchPoints' in navigator) && (navigator.maxTouchPoints >= 0);
         this.touch = !!('ontouchstart' in window) || (window.DocumentTouch && document instanceof window.DocumentTouch);
         
         this.device.pointer = this.pointer;
         this.device.touch = this.touch;
         
         // device screen resolution
         const screenWidth = window.innerWidth;
         const screenHeight = window.innerHeight;

         //console.log("window.innerWidth", window.innerWidth)
         //console.log("window.innerHeight", window.innerHeight)

         this.device.resolution = { width: screenWidth, height: screenHeight }
         this.device.orientation = (screenWidth > screenHeight) ? "landscape" : "portrait";
 
 
         // ensure the device is not a tablet, iPadOs 13 fix
         if (!this.ipad && this.macos && this.touch && this.iPadScreens.indexOf(screenWidth + "x" + screenHeight) >= 0 && (navigator.maxTouchPoints >= 0)) 
         {
             this.ipad = this.ua.match(/(Version)\/([\d.]+)/);
             if (!this.ipad) this.ipad = [0, 1, '13_0_0'];
             this.macos = false;
             this.windows = false;
         } 
 
         // check android
         if (this.android) 
         {
             this.device.os = 'android';
             this.device.android = true;
             this.device.type = (this.ua.match(/Tablet/i)) ? "tablet" : "phone";
         }
         
         // ios family
         if (this.ipad || this.iphone || this.ipod) 
         {
             this.device.os = 'ios';
             this.device.ios = true;
             this.device.type = (this.ipad) ? "tablet" : "phone";
         } 
 
         // check os, but ensure we are not on a mobile device
         if (!this.device.ios && !this.device.android)
         {
             if (this.windows || this.macos) 
             {
                 this.device.os = (this.windows) ? 'windows' : 'mac';
                 this.device.type = "desktop";
             }
         }
         
         // detect webp support
         this.device.webp = this.canUseWebP();
 
         // browsers
         this.ie11 = !!window.MSInputMethodContext && !!document.documentMode;
         this.edge = !!this.ua.match(/Edg/g) && this.ua.toLowerCase().indexOf('chrome') >= 0;
         this.chrome = this.ua.toLowerCase().indexOf('chrome') >= 0 && !this.edge;
         this.safari = this.ua.toLowerCase().indexOf('safari') >= 0 && this.ua.toLowerCase().indexOf('chrome') < 0 && this.ua.toLowerCase().indexOf('android') < 0;
         this.firefox = !!this.ua.match(/FxiOS/g);
         if (!this.firefox) this.firefox = !!this.ua.match(/Firefox/g);
 
         if (this.ie11) this.device.browser = "ie11";
         if (this.edge) this.device.browser = "edge";
         if (this.chrome) this.device.browser = "chrome";
         if (this.safari) this.device.browser = "safari";
         if (this.firefox) this.device.browser = "firefox";
 
         // add classes to html
         if (!this.isSSR) this.addHtmlClasses();
     }
 
     getInfo = () => {
         return this.device;
     }
 
     resolution = () => {
         return this.device.resolution;
     }
 
     get screenSize() {
         const { width, height } = this.device.resolution;
         
         return {
             width,
             height,
             type: this.device.type,
             os: this.device.os,
             mobile: this.isMobile(),
             portrait: this.isPortrait()
         }
     }
 
     addHtmlClasses = () =>
     {
         const html = document.querySelector("html");
         if (this.device.touch) html.classList.add("touch"); else html.classList.add("no-touch");
         html.classList.add(this.device.type);
         html.classList.add(this.device.orientation);
         html.classList.add(this.device.browser);
         // html.classList.add("screen-" + this.device.resolution.width + "-" + this.device.resolution.height);
         
         if (this.device.webp) html.classList.add('webp'); else html.classList.add('no-webp');
 
         if (!this.device.ios && !this.device.android)
         {
             if (this.macos) html.classList.add("macos");
             if (this.windows) html.classList.add("windows");
         }
         else 
         {
             if (this.device.ios) html.classList.add("ios");
             if (this.device.android) html.classList.add("android");
         }
 
         if (this.ie11) document.body.classList.add("ie11");
     }
 
     removeHtmlClasses = () =>
     {
         const html = document.querySelector("html");
         if (this.device.touch) html.classList.remove("touch"); else html.classList.remove("no-touch");
         html.classList.remove(this.device.type);
         html.classList.remove(this.device.orientation);
         html.classList.remove(this.device.browser);
         // html.classList.remove("screen-" + this.device.resolution.width + "-" + this.device.resolution.height);
         html.classList.remove('webp');
         html.classList.remove('no-webp');
 
         if (!this.device.ios && !this.device.android)
         {
             if (this.macos) html.classList.remove("macos");
             if (this.windows) html.classList.remove("windows");
         }
         else 
         {
             if (this.device.ios) html.classList.remove("ios");
             if (this.device.android) html.classList.remove("android");
         }
 
         if (this.ie11) document.body.classList.remove("ie11");
     }
 
     isWebP = () => 
     {
         return  this.device.webp;
     }
 
     isIE11 = () => 
     {
         return this.ie11;
     }
 
     isFirefox = () => 
     {
         return this.firefox;
     }
 
     isDesktop = () => 
     {
         return this.device.type === "desktop";
     }
 
     isTablet = () => 
     {
         return this.device.type === "tablet";
     }
     
     isMac = () => 
     {
         return this.device.os === "mac";
     }
     
     disableScrollMomentum = () => 
     {
         return this.isMac() || this.isTablet();
 }
 
     isPhone = () => 
     {
         return this.device.type === "phone";
     }
     
     isMobile = () => 
     {
         return ( this.isPhone() || this.isTablet() )
     }
 
     isLandscape = () => 
     {
         return this.device.orientation === "landscape";
     }
     
     isPortrait = () => 
     {
         return this.device.orientation === "portrait";
     }
 
     getNavigatorInstance = () => 
     {
         if (typeof window !== 'undefined') 
         {
             if (window.navigator || navigator) 
             {
                 return window.navigator || navigator;
             } 
         }
     
         return false;
     }
 
     checkGestures = () => 
     {
         return 'ongesturestart' in window;
     }
 
     canUseWebP() 
     {
         var elem = document.createElement('canvas');
     
         if (!!(elem.getContext && elem.getContext('2d'))) {
             // was able or not to get WebP representation
             return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
         }
     
         // very old browser like IE 8, canvas not supported
         return false;
     }
 }