/**
 * Author: Rafael Emmanuelli / Insytive 09-03-2020
 * 
 * Header
 * 
*/

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export default class ModuleContent extends Component
{
    static propTypes = {
        type: PropTypes.string,
        classes: PropTypes.string,
        textContentClass: PropTypes.string,
        isFullSize: PropTypes.bool,
        heading: PropTypes.string,
        subheading: PropTypes.string,
        bodyHeading: PropTypes.string,
        body: PropTypes.string
    }
    
    static defaultProps = {
        type: "module-content",
        classes: "",
        textContentClass: "colstart-2 colspan-20 vpos-100 valign-bottom text-align-center",
        isFullSize: false,
        heading: null,
        subheading: null,
        bodyHeading: null,
        body: null
    }

    render()
    {
        const { 
            type,
            children,
            classes,
            textContentClass,
            isFullSize,
            heading,
            subheading,
            bodyHeading,
            body
        } = this.props;
        
        const addClasses = classNames(classes, {
            "module-content-full": isFullSize,
            "module-content": !isFullSize,
            "m-slider-footer": type === "module-slider"
        })

        const textContentClasses = classNames("text-content", textContentClass);
        
        return (
            <Fragment>
                {isFullSize && <div className={addClasses}>
                    <div className={textContentClasses}>
                        {heading && <div className="header-module-top">
                            <div className="module-title">
                                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
                                <h1 className="heading" tabIndex={0}>{heading}</h1>
                            </div>
                            {subheading && <div className="module-subtitle">
                                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
                                <h2 className="sub-heading" tabIndex={0}>{subheading}</h2>
                            </div>}
                        </div>}
                        {type === "module-content" && <div className="header-module-bottom">
                            <div className="module-body">
                                <div className="body">
                                    {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
                                    {bodyHeading && <h2 className="body-heading" tabIndex={0}>{bodyHeading}</h2>}
                                    {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
                                    {body && <div className="body-text" tabIndex={0}>{body}</div>}
                                </div>
                            </div>
                            
                            <div className="module-footer-container">
                                {/*<div className={classNames("module-scroll-down", { ['show-scroll']: showScrollDown })}><ScrollDown /></div>*/}
                            </div>
                        </div>}
                        {type === "module-slider" && <div className="header-module-bottom">
                            {children}
                        </div>}
                    </div>
                </div>}

                {!isFullSize && <div className={addClasses}>
                    {children}
                </div>}
            </Fragment>
        )
    }
}