/**
 * Author: Rafael Emmanuelli
 * 
 * Sound Button
 * 
*/

import React, { Fragment, Component, createRef } from "react";
import { withPrefix } from 'gatsby';
import classNames from "classnames";
import ExternalAudio from "./ExternalAudio";

export default class SoundButton extends Component
{
    get audio() { return this.bgAudioRef.current; }

    constructor(props)
    {
        super(props);

        this.buttonRef = createRef();
        this.bgAudioRef = createRef();
        
        // tells us if user actually pressed sound button
        // this will be used to check when app plays audio automatically not 
        // to play it. Only way to play is for the user to click on the sound again
        this.userOff = false;               

        this.onInitPlay = this.onInitPlay.bind(this);
        this.onMouseClick = this.onMouseClick.bind(this);

        this.state = {
            isSound: false,
            loadSound: false,
            audioSource: null
        }
    }

    componentDidMount()
    {
        if (this.bgAudioRef.current.isPlaying())
            this.setState({ isSound: true });
        else 
        {
            if (this.bgAudioRef.current.externalLib.el)
                this.bgAudioRef.current.externalLib.el.addEventListener("play", this.onInitPlay)
        }
    }

    componentWillUnmount()
    {
        if (this.bgAudioRef && this.bgAudioRef.current)
        {
            this.bgAudioRef.current.externalLib.el.removeEventListener("play", this.onInitPlay);
        }
    }

    onInitPlay(e)
    {
        this.bgAudioRef.current.externalLib.el.removeEventListener("play", this.onInitPlay);
        this.setState({ isSound: true });
    }

    onMouseClick(e)
    {
        const { isSound } = this.state;

        let toggle = !isSound;
        this.userOff = true;
        this.setState({ isSound: toggle });

        window.__raid.ga.trackEvent('Website', 'Sound Button', toggle);

        if (toggle) this.playAudio(true);
        else this.stopAudio();
    }

    playAudio(fromU) // from user
    {
        if (this.userOff && !fromU) return;

        this.audio.playAudio();
        this.setState({ isSound: true, audioSource: withPrefix("static/audio/chemtrails.mp3") });
    }

    stopAudio()
    {
        this.audio.stopAudio();
        this.setState({ isSound: false });
    }

    isPlaying()
    {
        return this.audio.isPlaying();
    }

    render()
    {
        const { isSound, audioSource } = this.state;
        const { device } = this.props;

        return (
            <Fragment>
                <div ref={this.buttonRef} 
                    role="button"
                    aria-label="Sound Toggle Button"
                    aria-pressed={isSound ? "true" : "false"}
                    tabIndex={0}
                    className={classNames("sound-button", { "sound-off": !isSound })}
                    onClick={this.onMouseClick}
                    onKeyPress={this.onMouseClick}
                >
                    <div className="sound-label">Mute</div>
                    <div className="sound-icon-toggle">
                        <span className="sound-toggle-bar"></span>
                        <span className="sound-toggle-bar"></span>
                        <span className="sound-toggle-bar"></span>
                        <span className="sound-toggle-bar"></span>
                        <span className="sound-toggle-bar"></span>
                    </div>

                    <ExternalAudio ref={this.bgAudioRef} audioSource={audioSource} device={device} />
                    {/*<BackgroundAudio ref={this.bgAudioRef} audioSource={audioSource} device={device}/>*/}
                </div>
            </Fragment>
        )
    }
}