/**
 * Author: Rafael Emmanuelli / Insytive 09-03-2020
 * 
 * Header
 * 
*/

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

export default class RichLayoutRow extends Component
{
    static propTypes = {
        classes: PropTypes.string,
        columns: PropTypes.number
    }
    
    static defaultProps = {
        classes: "",
        columns: 1,
    }

    render()
    {
        const { classes, columns, children } = this.props;

        const classNamesList = classNames("richlayout-row", classes);

        const numOfColumns = children ? children.length : columns;

        return (
            <Fragment>
                <div className={classNamesList} data-cols={numOfColumns}>
                    {children}
                </div>
            </Fragment>
        )
    }
}