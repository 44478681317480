/**
 * Author: Rafael Emmanuelli
 * 
 * Video Progress Bar
 * 
*/

import React, { Fragment, Component, createRef } from "react";
import PropTypes from "prop-types";
import { curTimeDurText } from "../../../system/MiscTools";

export default class VideoProgressBar extends Component
{
    static propTypes = {
        device: PropTypes.any.isRequired,           // DeviceTool: get screenSize()
        videoEl: PropTypes.any,
        currentTime: PropTypes.number,
        durationTime: PropTypes.number,
        bufferedStart: PropTypes.number,
        bufferedEnd: PropTypes.number
    }
    
    constructor(props)
    {
        super(props);

        this.pRef = createRef();                    // component ref
        this.pIndRef = createRef();                 // progress indicator ref
        this.pCircRef = createRef();                // progress circle ref

        this.isOut = false;
        this.seek = 0;
        this.offsetWidth = 0;
        this.offsetLeft = 0;

        this.updateLayout = this.updateLayout.bind(this);
    }

    componentDidMount()
    {
        this.updateLayout();

        window.addEventListener("resize", this.updateLayout);
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        const { videoEl:el } = this.props;

        if (!el) return;

        if (this.offsetWidth === this.pRef.current.offsetWidth) return

        this.updateLayout();
    }

    componentWillUnmount()
    {
        window.removeEventListener("resize", this.updateLayout);
    }

    updateLayout(e)
    {
        const { device, videoEl:el } = this.props;
        
        if (!el) return;

        // if the video is not fullscreen, we need to get the offset from its left 
        // postion to the edge of the browser viewport 
        this.offsetWidth = this.pRef.current.offsetWidth;
        this.offsetLeft = Math.abs((device.width - el.offsetWidth) / 2);;

        // console.log("Bar.updateLayout", this.offsetLeft);
    }

    onOut(e)
    {
        //console.log("Bar.onMouseOut", e);

        this.isOut = true;

        this.pIndRef.current.classList.remove("show");
        this.pCircRef.current.classList.remove("show");
    }
    
    onOver(e)
    {
        //console.log("Bar.onMouseOver", e)

        this.isOut = false;

        this.pIndRef.current.classList.add("show");
        this.pCircRef.current.classList.add("show");
    }

    onMove(e)
    {
        if (this.isOut) return;

        const { durationTime: dT } = this.props;

        let offsetX = (e.clientX -  this.pRef.current.offsetLeft) - this.offsetLeft;
        if (offsetX >= this.offsetWidth) offsetX = this.offsetWidth;
        if (offsetX <= 0) offsetX = 0;

        const percent = offsetX /  this.pRef.current.offsetWidth;
        const timePos = dT * percent;
        
        this.seek = timePos;
        this.pIndRef.current.style.transform = "translate(" + offsetX + "px, 0)";
    }
    
    onClick(e)
    {
        const { videoEl:el } = this.props;

        if (this.seek) el.currentTime = this.seek;
    }

    render()
    {
        const { currentTime:cT, durationTime: dT, bufferedEnd: bEnd  } = this.props;

        const timeSt = {
            width: ((cT / dT) * 100) + "%"
        }

        const bufferSt = {
            width: bEnd !== undefined ? ((bEnd / dT) * 100) + "%" : 0
        }

        return (
            <Fragment>
                <div ref={this.pRef} 
                    aria-label="Progress Bar"
                    className="video-progress-bar"
                    role="progressbar"
                    tabIndex={0}
                    aria-valuemin="0"
                    aria-valuemax={dT}
                    aria-valuenow={cT}
                    aria-valuetext={curTimeDurText(cT, dT)}
                    onKeyPress={(e) => this.onClick(e)}
                    onClick={(e) => this.onClick(e)}
                    onFocus={(e) => this.onOver(e)}
                    onMouseOver={(e) => this.onOver(e)}
                    onBlur={(e) => this.onOut(e)}
                    onMouseOut={(e) => this.onOut(e)}
                    onMouseMove={(e) => this.onMove(e)}>
                    <div className="video-progress-base-bar">
                        <div className="video-progress-buffer-bar" style={bufferSt}></div>
                        <div className="video-progress-time-bar" style={timeSt}>
                            <div ref={this.pCircRef} className="video-progress-circle-indicator"></div>
                        </div>
                    </div>
                    <div ref={this.pIndRef} className="video-progress-indicator"></div>
                </div>
            </Fragment>
        )
    }
}